import {DateTime} from "luxon"

import {FieldDef} from "../modeler/FieldDef.js"
import {MC} from './MC.js'
import {MCCache} from "./MCCache.js"
import {MCHistory} from "./MCHistory.js"
import {MCBrws} from "./MCBrws.js"
import {Expression} from "./Expression.js"
import {ReactFlow} from "./ReactFlow.jsx"
import {Value} from "./Value.js"

let Flow = function() {}

Flow.prototype.init = function(reactFlow) {
  this.opStart = performance.now()
  this.opStartDate = Date.now()
  this.instanceId = MC.generateId()
  if (reactFlow) {
    this.reactFlowObj = reactFlow
  }
  this.parentFlow = null
  this.flowName = null
  this.lang = null
  this.flow = null
  this.input = Value.v(null)
  this.inputMapTrace = null
  this.afterRenderForm = null
  this.serverSide = false
  this.cache = false
  this.confPath = null
  this.context = {data: {env: Value.dataNode({})}}
  this.wantedLogLevel = null
  this.logLevel = null
  this.logicTimers = {}
  this.baseUrl = null
  return this
}  

Flow.prototype.setParentFlow = function(pFlow) {
  this.parentFlow = pFlow;
  return this;
};

Flow.prototype.setFlowConfiguration = function(vConfiguration, fConFlowName) {
  this.confPath = vConfiguration
  this.flowName = fConFlowName;
  return this;
};

Flow.prototype.setConfPath = function(fConfPath) {
  this.confPath = fConfPath
  return this;
}

Flow.prototype.setBaseUrl = function(base) {
  this.baseUrl = base
  return this
}

Flow.prototype.setLang = function(val) {
  this.lang = val;
  return this;
}

Flow.prototype.setInstanceId = function(val) {
  this.instanceId = val
  return this
}

Flow.prototype.setServerSide = function(serverSide, iface) {
  this.serverSide = serverSide
  if (!MC.isNull(iface)) {
    iface.id = this.flowName
    if (iface.cache === true) {
      this.cache = true
    }
    iface.exception = Object.assign({}, MC.genericExceptions, iface.exception)
    this.flow = iface
  }
  return this
}

Flow.prototype.setCacheable = function() {
  this.cacheable = true
}

Flow.prototype.setAfterRenderFormFunction = function(val) {
  if (MC.isFunction(val)) {
    this.afterRenderForm = val;
  } else {
    this.endOperationException('SYS_UnrecoverableRuntimeExc', "Parameter of called 'setAfterRenderFormFunction' has to be function!");
  }
  return this;
};

Flow.prototype.setEnv = function(env) {
  this.context.data.env.value = Object.assign({}, env.value)
}

Flow.prototype.setWantedLogLevel = function(level) {
  if (!level) {
    this.wantedLogLevel = null
    return
  }
  if (level == 'true' || level == true) {
    level = 'AUTO'
  }
  const knownParams = ['NONE', 'AUTO', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
  if (knownParams.indexOf(level) < 0) {
    level = null
  }
  this.wantedLogLevel = level
}

Flow.prototype.setContextCallActionParent = function(action) {
  this.contextCallActionParent = action
}

Flow.prototype.cacheKey = function() {
  return this.confPath + '/' + this.flowName + ':' + JSON.stringify(this.input)
}

Flow.prototype.loadAndStart = function(input, inputMapTrace) {
  let self = this
  return new Promise((resolve, reject) => {
    self.resolve = resolve
    self.reject = reject
    if (!self.confPath || !self.flowName || !self.lang) {
      self.endOperationException('SYS_UnrecoverableRuntimeExc', 'Configuration path, flow name and lang must be set when starting flow!')
      return
    }
    self.input = input || Value.v(null)
    self.inputMapTrace = inputMapTrace
    let origConf =  Value.getProperty(self.context.data.env, 'configuration')
    if (!Value.isNullOrEmpty(origConf)) {
      self.loadAndStartStep1(origConf)
    } else {
      MC.getConfiguration(self.confPath, self.wantedLogLevel, this, self.baseUrl, self.reactFlow().props.mconf.lang).then(function (conf) {
        self.loadAndStartStep1(Value.fromJson(conf))
      })
    }
  })
}

Flow.prototype.loadAndStartStep1 = function(conf) {
  if (this.reactFlow() && !this.parentFlow) {
    let loaderState = {}
    if (!this.reactFlow().props.parent && !Value.isNullOrEmpty(conf) && ['init', 'none', 'all'].indexOf(Value.getProperty(conf, 'mini:loader').value) >= 0) {
      loaderState.loader = Value.getProperty(conf, 'mini:loader').value
    }
    this.reactFlow().requestDimmer(loaderState, conf)
    if (Value.hasProperty(conf, 'mini:sessionInactivityTimeout')) {
      this.reactFlow().initActivityMonitor(Value.getProperty(conf, 'mini:sessionInactivityTimeout').value)
    }
  }
  if (!this.reactFlow()) {
    return
  }
  let self = this;
  MC.getFlowDefinition(self.flowName, self.confPath, self.lang, self.baseUrl, this).then((data) => {
    self.flow = data
    self.selectLogLevel(conf)
    if (self.serverSide == 'server' || !data.isFrontend) {
      self.serverSide  = true
    } else {
      this.serverSide = false
    }
    if (self.serverSide) {
      if (this.runMock()) {
        return
      }
      self.runOnServer();
    } else {
      if (self.cacheable) {
        let key = self.cacheKey()
        if (MCCache.has(key)) {
          self.takenFromCache = true
          self.endOperation(MCCache.get(key), null, false)
          return
        }
      }
      if (!self.reactFlow()) {
        return
      }
      let env = this.context.data.env
      let system = {}
      system.flowId = self.instanceId
      system.correlationId = MC.correlationId
      system.flowLogId = self.instanceId
      if (navigator.userAgentData) {
        let nodeName = ""
        for (let brand of navigator.userAgentData.brands) {
          nodeName += brand.brand + " " + brand.version + ", "
        }
        nodeName += navigator.userAgentData.mobile ? "mobile" : "desktop"
        system.nodeName = nodeName           
      } else {
        system.nodeName =  navigator.userAgent
      }
      system.userAgent = system.nodeName
      system.flowConfig = self.confPath
      system.flowConfigStorageResource = self.confPath.replace(/;[^;\/?]+=[^;\/?]+/g, (match) => { return match.startsWith(";v=") ? match : "" })
      system.language = self.lang
      system.userLoginId = self.reactFlow().props.mconf.userId
      system.localTimezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone
      system.serverUrl = window.location.origin + '/'
      system.serverTimezoneId = this.reactFlow().props.mconf.serverTimezoneId
      env.value.system = Value.fromJson(system)
      if (!Value.isNullOrEmpty(conf)) {
        env.value.configuration = conf
      }
      let operation = {}
      operation.operationName = self.flow.id;
      operation.rootOperationName = self.parentFlow ? self.parentFlow.flow.id : self.flow.id
      operation.component = self.flow.model
      env.value.operation = Value.fromJson(operation)
      env.value.ns = Value.fromJson(self.flow.ns)
      if (this.parentFlow) {
        this.adjustNsMap(Value.getProperty(this.parentFlow.context.data.env, 'ns'))
      }
      if (Value.isNullOrEmpty(Value.getProperty(env, 'context'))) {
        MC.getEnvironmentContext(self.confPath, Value.getProperty(conf, 'fl:environmentOperation').value, self.baseUrl, self.reactFlow().props.mconf.lang).then(function(context) {
          env.value.context = Value.fromJson(context)
          if (!MC.isNull(context) && MC.isPlainObject(context)) {
            if (MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
              self.setLang(context.request.language)
            }
            if (MC.isPlainObject(context.internalUser) && !MC.isNull(context.internalUser.internalUserId)) {
              env.value.system.value.userLoginId = Value.v(context.internalUser.internalUserId, 'string')
            }
          }
          self.loadAndStartStep2()
        }).catch(function (err) {
          if (navigator.onLine) {
            self.endOperationException('SYS_IntegrationExc', 'Reading environment context failed: ' + err)
            return
          } else {
            self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available: ' + err)
            return
          }
        })
      } else {
        self.loadAndStartStep2()
      }
    }
  }).catch(function (err) {
    if (navigator.onLine) {
      self.endOperationException('SYS_IntegrationExc', 'Reading flow definition failed: ' + err)
      return
    } else {
      self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available: ' + err)
      return
    }
  })
}

Flow.prototype.loadAndStartStep2 = function() {
  if (!this.flow.action && !this.parentFlow && !this.reactFlow().props.embedded) { // root operation not in dialog
    MCHistory.history(this, null, 'OPERATION START', {'Input': this.input, 'Environment': this.debug('TRACE') ? this.context.data.env : null}, {end: this.opStartDate})
  } else if (this.parentFlow) {
    if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.flow.id) < 0) {
      let me = this
      let parent = this.parentFlow
      if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.parentFlow.flow.id) > -1 && this.parentFlow.parentFlow) {
        parent = this.parentFlow.parentFlow
        me = this.parentFlow
      }
      if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.parentFlow.flow.id) == -1 || this.parentFlow.parentFlow) { // not for FWK_OperationExecute or not in dialog, in dialog started by call action logged already
        MCHistory.history(parent, this.contextCallActionParent || parent.context.action, "CALL ACTION START", {'Input': me.input, 'Trace': me.inputMapTrace, executionId: me.instanceId, target: me.flow})
      }
      if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.parentFlow.flow.id) > -1) {
        if (this.parentFlow.parentFlow) { // not in dialog has parentFlow.parentFlow
          MCHistory.history(me, {kind: 'framework', code: me.flow.id, id: me.flow.rbsid}, "OPERATION START", {'Input': me.input, 'Trace': me.inputMapTrace, executionId: this.instanceId, target: this.flow})
        } else { // in dialog not has has parentFlow.parentFlow
          MCHistory.history(parent, {kind: 'framework', code: parent.flow.id, id: parent.flow.rbsid}, "OPERATION START", {'Input': parent.input, 'Trace': parent.inputMapTrace, executionId: me.instanceId, target: this.flow})
        }  
      }
    }
  }
  if (!MC.isNull(this.flow.svl)) {
    this.addToContext(this.context.data, 'svl', Value.fromJson(this.flow.svl))
  }
  if (!MC.isNull(this.flow.vmt)) {
    this.addToContext(this.context.data, 'vmt', Value.fromJson(this.flow.vmt))
  }
  this.progress({'Input': this.input, 'Trace': this.inputMapTrace})
}

Flow.prototype.selectLogLevel = function(conf) {
  if (this.wantedLogLevel === 'AUTO') {
    const knownParams = ['NONE', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
    let componentLoggingThreshold = Value.getProperty(conf, 'fl:componentLoggingThreshold')
    if (!Value.isNullOrEmpty(componentLoggingThreshold)) {
      for (let cmpSetting of Value.collectionValue(Value.castToCollection(Value.getProperty(conf, 'fl:componentLoggingThreshold')))) {
        cmpSetting = Value.toJson(cmpSetting)
        if (cmpSetting['fl:name'] && (cmpSetting['fl:level'] || cmpSetting['fl:operationLoggingThreshold'])) {
          if (cmpSetting['fl:name'].startsWith('/') && cmpSetting['fl:name'] ===  '/' + this.flow.model || this.flow.model.endsWith('/' + cmpSetting['fl:name'])) {
            if (cmpSetting['fl:operationLoggingThreshold']) {
              for (let opSetting of MC.asArray(cmpSetting['fl:operationLoggingThreshold'])) {
                if (MC.asArray(opSetting['fl:name']).indexOf(this.flow.id) >= 0) {
                  if (knownParams.indexOf(opSetting['fl:level']) < 0) {
                    this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:operationLoggingThreshold/fl:level' must be one of ${knownParams}!`);
                  } else {
                    this.logLevel = opSetting['fl:level']
                  }
                }
              }
            }
            if (!this.logLevel && cmpSetting['fl:level']) {
              if (knownParams.indexOf(cmpSetting['fl:level']) < 0) {
                this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:componentLoggingThreshold/fl:level' must be one of ${knownParams}!`)
              } else {
                this.logLevel = cmpSetting['fl:level']
              }
            }
          }
        }
      }
    }
    if (!this.logLevel) {
      let defaultLoggingThreshold = Value.getProperty(conf, 'fl:defaultLoggingThreshold')
      if (!Value.isNullOrEmpty(defaultLoggingThreshold)) {
        if (knownParams.indexOf(defaultLoggingThreshold.value) < 0) {
          this.endOperationException('SYS_UnrecoverableRuntimeExc', `Value of configuration parameter 'fl:defaultLoggingThreshold' must be one of ${knownParams}!`)
        } else {
          this.logLevel = defaultLoggingThreshold.value
        }
      } else {
        this.logLevel = 'TRACE'
      }
    }
  } else if (this.wantedLogLevel) {
    this.logLevel = this.wantedLogLevel
  } else {
    this.logLevel = null
  }
}

Flow.prototype.progress = function(logObject) {
  if (this.getRootFlow().isRenderingInterupted) {
    return
  }
  if (this.runMock()) {
    return
  }
  if (this.flow.kind == 'function') {
    this.runFunctionOperation();
  } else if (this.flow.kind == 'framework') {
    this.runFrameworkOperation();
  } else if (this.flow.kind == 'decisiontable') {
    this.runDecisionTableOperation()
  } else if (this.flow.kind == 'ruleset') {
    this.runRulesetOperation()
  } else if (this.flow.action) {
    if (!this.context.nextAction) {
      this.context.nextAction = this.getStartAction();
    } else {
      let text = null
      if (logObject.isException) {
        text = 'EXCEPTION END'
      }
      if (logObject.takenFromCache) {
        text = 'FROM CLIENT CACHE'
        delete logObject.takenFromCache
      }
      MCHistory.history(this, this.context.action, text, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: this.context.actionStart ? performance.now() - this.context.actionStart : 0})
    }  
    this.context.action = this.context.nextAction;
    this.initActionStartTime()
    this.context.altmapping = this.context.nextAltmapping
    this.context.nextAction = this.getActionById(this.context.action.nextaction)
    this.context.nextAltmapping = this.context.action.altmapping
    if (!this.context.nextAction && this.context.action.kind != 'end' && this.context.action.kind != 'decision' && !(this.context.action.kind == 'call' && this.context.action.leaveFlow)) {
      this.endOperationException('SYS_InvalidModelExc', this.context.action.kind + " action must have next action defined!");
      return;
    }
    var state = this.context.action.kind;
    switch (state) {
      case "start":
        this.startAction();
        break;
      case "form":
        this.formAction();
        break;
      case "call":
        this.callAction();
        break;
      case "decision":
        this.decisionAction();
        break;
      case "end":
        this.endAction();
        break;
      case "transform":
        this.transformAction();
        break;
      case "feedback":
        this.feedbackAction();
        break;
      default:
        this.endOperationException('SYS_InvalidModelExc', 'Unsupported action kind: "' + state + '"!');
        break;
    }
  } else {
    this.endOperationException('SYS_InvalidModelExc', "Operation has no action or has not supported operation type '" + this.flow.kind + "'!");
  }
};

Flow.prototype.progressLazyForm = function(formData, logObject, lazyAction, lazyActionLogic, altMapping) {
  let self = this
  let text = null
  if (!MC.isNull(lazyAction)) {
    if (logObject.takenFromCache) {
      text = 'FROM CLIENT CACHE'
      delete logObject.takenFromCache
    }
    MCHistory.history(this, lazyAction, text, logObject, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    this.initActionStartTime()
  }
  if (self.context.action.kind !== 'form' || lazyActionLogic && lazyActionLogic.formExecutionId && lazyActionLogic.formExecutionId != self.formExecutionId) {
    return
  }
  if (!MC.isNull(this.context.dataActions)) {
    let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), self.context)
    if (input) {
      try { 
        self.setFormFields(formData, input)
      } catch (ex) {
        this.endOperationException('SYS_MappingExc', ex.message, null, null, null, trace)
      }
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    MCHistory.history(self, self.context.action, 'FORM PRE-RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    this.formData = formData
    this.initActionStartTime()
    self.callAction(this.context.dataActions.pop())
  } else {
    delete this.context.dataActions;
    let cont = {data: this.context.data}
    if (!this.hardRenderMode) {
      cont.triggeredByField = logObject.triggeredByField
      delete logObject.triggeredByField
      cont.inForm = this.context.action.code
      cont.logicName = lazyActionLogic?.name
    }
    let [input, trace] = this.mapToResultObject(this.selectMapping(self.context.action, altMapping), cont)
    if (input) {
      try {
        self.setFormFields(formData, input)
      } catch (ex) {
        this.endOperationException('SYS_MappingExc', ex.message, null, null, null, trace)
      }
    } else {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    let formTitle = formData.title
    if (formData.param && formData.param['@title']) {
      formTitle = formData.param['@title']
    }
    if (formTitle) {
      document.title = formTitle
    }
    if (MC.isFunction(self.afterRenderForm)) {
      self.afterRenderForm(formData, true)
    }
    let rootFlow = this.getRootFlow()
    if (!MC.isNull(rootFlow.context.feedback)) {
      formData.feedback = rootFlow.context.feedback
      rootFlow.context.feedback = []
    }
    if(formData.flow && formData.flow.modelerReact) {
      formData.flow.modelerReact.resetStacks();
    }
    if (this.reactFlow()) {
      if (this.reactFlow().props.autoScrollUp !== false && this.hardRenderMode) { // it called only in form action and not in dialogs
        // scroll up must be called before setState for working focus
        window.scrollTo(0, 0);
      }
      if (this.hardRenderMode) {
        this.getRootFlow().runReady = true
        this.hardRenderMode = false;
      }
      MCHistory.history(self, self.context.action, 'FORM RENDER', {'Input': input, 'Trace' : trace, target: formData}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart});
      this.context.data['@lastFormAction'] = Value.v(this.context.action.code, 'string')
      this.reactFlow().stopDimmer({state: 'form', formData: formData, firstFormRendered: true})
    }
    if (lazyActionLogic) {
      this.initLogicTimer(lazyActionLogic)
      if (!MC.isNull(lazyActionLogic.logic)) {
        for (let logic of MC.asArray(lazyActionLogic.logic)) {
          this.eventForm(null, null, null, null, {logic: formData.logic.find((item) => logic == item.name), triggerLogic: lazyActionLogic.name})
        }
      }
      if (lazyActionLogic.event.find(e => e.e == 'beforeunload')) {
        this.reactFlow().resolveUnload(formData)
      }
    }
  }
};

Flow.prototype.getStartAction = function() {
  if (this.flow.action) {
    for (var i=0; i < this.flow.action.length; i++) {
      if (this.flow.action[i].code == 'start') {
        return this.flow.action[i];
      }
    }
  }
  this.endOperationException('SYS_InvalidModelExc', "Can not find start action!");
  return null;
};

Flow.prototype.getActionById = function(id) {
  if (this.flow.action) {
    for (var i=0; i < this.flow.action.length; i++) {
      if (this.flow.action[i].id == id) {
        return this.flow.action[i];
      }
    }
  }
  return null;
}

Flow.prototype.convertInputTreeData = function(inputTree, valueTree) {
  let res = {}
  if (Value.isCollectionNotEmpty(valueTree)) {
    valueTree = Value.getFirstNotNull(valueTree)
  }
  for (let param of inputTree.input) {
    let key = param.name
    if (MC.isNull(key)) {
      if (param.basictype == 'anyType' && !Value.isNull(valueTree)) {
        MC.extend(res, valueTree.value)
      }
    } else {
      if (key.endsWith('*')) {
        key = key.substring(0, key.length-1)
      }
      let inValues = Value.getProperty(valueTree, key)
      if (!Value.isNull(inValues)) {
        let values
        if (param.name.endsWith('*')) {
          inValues = Value.castToCollection(inValues)
          values = []
          for (let ivalue of Value.collectionValue(inValues)) {
            let value
            if (param.input && !Value.isEmpty(ivalue)) {
              value = this.convertInputTreeData(param, ivalue)
              if (!value) {
                return false
              }
            } else {
              try {
                value = (param.basictype == 'anyType') ? ivalue : Value.castToScalar(ivalue, param.basictype)
              } catch (e) {
                e.message = 'Error parsing operation input - parameter: ' + param.name + ', value: ' + Value.toLiteral(ivalue) + ', message: ' + e.message
                this.endOperationException('SYS_MappingExc', e, this.input, null, null, this.inputMapTrace)
                return false
              }
            }
            values.push(value)
          }
          values = Value.v(values, 'collection')
        } else {
          if (Value.isCollectionNotEmpty(inValues)) {
            inValues = Value.getFirstNotNull(inValues)
          }
          if (param.input && !Value.isEmpty(inValues)) {
            values = this.convertInputTreeData(param, inValues)
            if (!values) {
              return false
            }
          } else {
            try {
              values = (param.basictype == 'anyType') ? inValues : Value.castToScalar(inValues, param.basictype)
            } catch (e) {
              e.message = 'Error parsing operation input - parameter: ' + param.name + ', value: ' + Value.toLiteral(inValues) + ', message: ' + e.message
              this.endOperationException('SYS_MappingExc', e, this.input, null, null, this.inputMapTrace)
              return false
            }
          }
        }
        if (!Value.isNull(values)) {
          res[key] = values
        }
      } else {
        if (param.mandat) {
          this.endOperationException('SYS_ValidationExc', "Input parameter '" + param.name + "' must have value!")
          return false
        }
      }
    }
  }
  return MC.isEmptyObject(res) ? Value.v(null) : Value.dataNode(res)
}

Flow.prototype.addToContext = function(context, actionCode, object) {
  if (MC.isNull(object)) {
    delete context[actionCode]
  } else {
    context[actionCode] = object
  } 
}

Flow.prototype.startAction = function() {
  if (this.flow.input && Array.isArray(this.flow.input)) {
    let result = this.convertInputTreeData(this.flow, this.input)
    if (result) {
      this.addToContext(this.context.data, 'start', result);
      this.progress({'Input': this.input, 'Output': result, 'Environment': this.debug('TRACE') ? this.context.data.env : null});
    }
  } else {
    this.progress({'Input': this.input, 'Environment': this.debug('TRACE') ? this.context.data.env : null});
  }
}

Flow.prototype.findAllLazyDataActions = function(field, result) {
  if (!result) {
    result = []
  }
  if ('embeddeddialog' == field.widget) {
    return result
  }
  const opts = this.getDataActionOpts(field)
  if (!MC.isNull(opts.action)) {
    if (!result.find(it => it.action == opts.action)) {
      result.push(opts)
    }
  } else if (field.fields) {
    for (let i = 0; i < field.fields.length; i++) {
      result = this.findAllLazyDataActions(field.fields[i], result)
    }
  }
  return result 
}

Flow.prototype.getDataActionOpts = function(field) {
  const dataEvent = MC.getFieldParamValue(field.param, '@dataEvent')
  let opts = {} 
  if (dataEvent) {
    if (Array.isArray(this.context.action.actionLink)) {
      let event = this.context.action.actionLink.find(el => el.name == dataEvent)
      if (!event) {
        this.endOperationException('SYS_InvalidModelExc', "Data event with name '" + dataEvent + "' not found in action '" + this.context.action.code + "'!")
        return
      }
      if (event && event.nextaction) {
        opts.altMapping = event.altmapping
        opts.altMappingBack = event.altmappingback
        opts.action = this.getActionById(event.nextaction)
        opts.triggeredByField = field
      }
    }
  }
  return opts
}

Flow.prototype.formAction = function() {
  this.doSubmitByParentResolve(false)
  let formId = this.context.action.form
  if (!formId) {
    this.endOperationException('SYS_InvalidModelExc', "Form action '" + this.context.action.code + "' has no form selected!")
    return
  }
  let form = this.flow.form[formId]
  let dataActions = this.findAllLazyDataActions(form)
  if (!MC.isNull(dataActions)) {
    this.context.dataActions = dataActions
  }
  this.hardRenderMode = true
  this.formExecutionId = MC.generateId()
  if (!this.reactFlow()) {
    return
  }
  this.reactFlow().isFormActive = true
  this.progressLazyForm(this.prepareFormData(MC.extend({}, form), formId), null, null, null, this.context.altmapping)
}

Flow.prototype.prepareFormData = function(formData, formId) {
  formData.formId = formId;
  formData.model = this.flow.model;
  formData.lang = this.lang;
  MC.setPropertyRecusively(formData, 'fields', 'flow', this)
  MC.initParentFields(formData)
  return formData;
}; 

Flow.prototype.handleSubmit = function(field, action) {
  if (!MC.isEmptyObject(field)) {
    if (MC.isModelerActive(field)) {
      return
    }
    if (!action) {
      action = field.param['@behavior']
    }
  }
  if (!['submit', 'cancel', 'store'].includes(action)) {
    return
  }
  let self = this
  if (action == 'submit') {
    self.eventForm(field, 'beforesubmit') 
    self.focusedOnFirst = false
  }  
  self.reactFlow().validateByParent(action).then(() => {
    self.reactFlow().handleSubmitByParentChilds(action).then(() => {
      self.submitForm(field, action, null, this.formExecutionId)
    }).catch(() => {})
  }).catch(() => { 
    self.reactFlow().forceUpdate() 
  })
}

Flow.prototype.submitForm = function(triggeredByField, behaviour, submitAction, formExecutionId) {
  if (this.context.action.kind !== 'form' || this.formExecutionId != formExecutionId) {
    return
  }
  this.clearLogicTimers();
  if (triggeredByField && MC.getFieldParamBooleanValue(triggeredByField.param, '@confirm')) {
    let message = MC.getFieldParamValue(triggeredByField.param, '@confirmMessage')
    if (!message) {
      message = MC.formatMessage("confirm", this.reactFlow().props.mconf.lang);
    }
    this.reactFlow().setState({message: {heading: message, size: 'tiny', onClose: this.cancelSubmitForm.bind(this), buttons: [
          {title: "OK", class: "positive", icon: "checkmark icon", action: this.confirmSubmitForm.bind(this, triggeredByField, behaviour, submitAction, formExecutionId)},
          {title: MC.formatMessage("cancel", this.reactFlow().props.mconf.lang), class: "negative",  icon: "cancel icon", action: this.cancelSubmitForm.bind(this)}
        ]}})
  } else {
    this.confirmSubmitForm(triggeredByField, behaviour, submitAction, formExecutionId)
  }
}

Flow.prototype.doSubmitByParentResolve = function(state) {
  if (!this.reactFlow()) {
    return
  }
  if (this.reactFlow().submitByParentResolve && this.reactFlow().submitByParentReject) {
    if (state) {
      this.reactFlow().submitByParentResolve()
    } else {
      this.reactFlow().submitByParentReject()
    }
    this.reactFlow().submitByParentResolve = false
    this.reactFlow().submitByParentReject = false
  }
}  

Flow.prototype.initActionStartTime = function() {
  this.context.actionStart = performance.now()
  this.context.actionStartDate = Date.now()
}

Flow.prototype.initActionIterationStartTime = function() {
  this.context.actionIterationStart = performance.now()
  this.context.actionIterationStartDate = Date.now()
}

Flow.prototype.confirmSubmitForm = function(triggeredByField, behaviour, submitAction, formExecutionId) {
  if (this.context.action.kind !== 'form' || this.formExecutionId != formExecutionId) {
    return
  }
  this.initActionStartTime()
  var action = this.context.action;
  let repeaterRows = triggeredByField ? MC.getFieldParamValue(triggeredByField.param, '@iteration') : null
  this.formExecutionId = null
  let output = this.mapFormOutput(this.reactFlow().state.formData, {}, triggeredByField, behaviour, 'no', repeaterRows, submitAction)
  this.addToContext(this.context.data, action.code, output);
  this.reactFlow().isFormActive = false
  if (this.reactFlow().state.loader == 'all') {
    this.reactFlow().requestDimmer({message: null})
  } else {
    this.reactFlow().setState({message: null})
  }
  this.progress({'Output': output, target: this.reactFlow().state.formData})
}

Flow.prototype.cancelSubmitForm = function() {
  this.reactFlow().setState({message: null});
};

Flow.prototype.ensureFieldFromRows = function(field) {
  return field && field.id == 'rows*' ? field.parent.parent : field
} 

Flow.prototype.setClipboardData = async function(context) {
  let clipboardItems = await navigator.clipboard.read()
  for (const type of clipboardItems[0].types) {
    if (type == 'text/html') continue
    const blob = await clipboardItems[0].getType(type)
    if (type == 'text/plain') {
      let text = await blob.text()
      context.clipboardData = Value.dataNode({text: Value.v(text), type: Value.v(type)})
    } else {
      let base64 = await MC.blobToBase64(blob)
      base64 = base64.split(',')[1]
      context.clipboardData = Value.dataNode({data: Value.v(base64), type: Value.v(type)}) 
    }
  }
}

Flow.prototype.eventForm = async function(triggeredByField, event, target, receivedData, options = {}) {
  if (!this.reactFlow() || options?.e?.repeat) {
    return
  }
  let self = this
  var formData = self.reactFlow().state.formData;
  var logic = [];
  let resolveUnload = 'beforeunload' == event
  let logicField = this.ensureFieldFromRows(triggeredByField)
  if (!MC.isNull(formData) && Array.isArray(formData.logic)) {
    for (var i = 0; i < formData.logic.length; i++) {
      var actl =  formData.logic[i];
      if (Array.isArray(actl.event)) {
        for (var e = 0; e < actl.event.length; e++) {
          if (actl.event[e]['e'] == event && (Array.isArray(actl.event[e]['f']) && logicField && actl.event[e]['f'].indexOf(logicField.rbsid) > -1 || MC.isNull(actl.event[e]['f']) && (!logicField || logicField.formId))) {
            if (logic.indexOf(actl) == -1) {
              if (event == 'click') {
                var behavior = MC.getFieldParamValue(logicField.param, '@behavior')
                if (!MC.isNull(behavior) && behavior !== '' && behavior !== 'formlogic') {
                  continue;
                }
              }
              logic.push(actl);
            }
          }
        }
      }
      if (options.logic == actl) { // call from timer
        if (logic.indexOf(actl) == -1) {
          logic.push(actl)
        }  
      }
    }
  }
  if (logic.length > 0) {
    let context = {}
    context['@event'] = MC.isNull(event) ? Value.v(options.triggerLogic, 'string') : Value.v(event, 'string')
    if (triggeredByField) {
      if (!triggeredByField.formId) {
        context['@widgetName'] = Value.v(logicField.id, 'string')
        context['@widgetPath'] = Value.v(self.getFormFieldPath(triggeredByField), 'string')
        if (MC.getFieldParamValue(triggeredByField.param, '@iteration')) {
          context['@widgetIndex'] = Value.fromJson(MC.getFieldParamValue(triggeredByField.param, '@iteration'))
        }
      }
    }
    if (target && target.field) {
      context['@widgetTarget'] = Value.v(this.ensureFieldFromRows(target.field).id, 'string')
      context['@widgetTargetPath'] = Value.v(self.getFormFieldPath(target.field), 'string')
      if (MC.getFieldParamValue(target.field.param, '@iteration')) {
        context['@widgetTargetIndex'] = Value.fromJson(MC.getFieldParamValue(target.field.param, '@iteration'))
      }
      if (options.e?.relatedTarget && target.node) {
        const thisFormEl = target.node.closest('.mnc.form')
        const fieldEl = options?.e.relatedTarget.closest('[data-widget-name]')
        if (fieldEl && thisFormEl.contains(fieldEl)) {
          context['@relatedTarget'] = Value.v(fieldEl.getAttribute('data-widget-name'), 'string')
          let parent = fieldEl
          let path = ''
          while (parent && thisFormEl.contains(parent)) {
            path = parent.getAttribute('data-widget-name') + (path == '' ? '' : (parent.classList.contains('widget-table') ? '/rows*/' : '/')) + path
            parent = parent.parentElement.closest('[data-widget-name]') // must be call on parent because then is self ignoed, prevention of infinity loop
          }
          context['@relatedTargetPath'] = Value.v(path, 'string')
          const indexEl = fieldEl.closest('[data-index]')
          if (indexEl && thisFormEl.contains(indexEl)) {
            context['@relatedTargetIndex'] = Value.fromJson([indexEl.getAttribute('data-index')])
          }
        }
      }
    }
    context['@scrollTop'] = Value.v(Math.round(window.pageYOffset), 'int')
    context['@scrollLeft'] = Value.v(Math.round(window.pageXOffset), 'int')
    context['env'] = Value.v(null)
    context['svl'] = Value.v(null)
    context['vmt'] = Value.v(null)
    for (let prop in self.context.data) {
      if (prop == 'env' || prop == 'svl' || prop == 'vmt') {
        context[prop] = self.context.data[prop]
      }
    }
    if (!MC.isNull(receivedData) && MC.isPlainObject(receivedData)) {
      context['eventData'] = Value.fromJson(receivedData);
    }
    if (!logicField) {
      logicField = true
    }
    if (options?.e) {
      let e = options.e
      let kData = {}
      kData.key = e.key
      kData.isAlt = e.altKey
      kData.isCtrl = e.ctrlKey
      kData.isShift = e.shiftKey
      kData.isMeta = e.metaKey
      context.keyboardData = Value.fromJson(kData)
    }
    if (options.dragData) {
      context.dragData = Value.fromJson(options.dragData)
    }
    let rerender = false
    let trc = this.debug('TRACE')
    loopLogics:
    for (var l = 0; l < logic.length; l++) {
      let lStart = performance.now()
      let lStartDate = Date.now()
      let trcObj = []
      if (Array.isArray(logic[l].condition) && logic[l].condition.length > 0) {
        if (logic[l].readsClipboardInCodition) {
          await this.setClipboardData(context)
        }
        let opts = {trace: trc, flow: this, triggeredByField: triggeredByField, function: this.flow.function, inForm: 'form'}
        for (var i = 0; i < logic[l].condition.length; i++) {
          try {
            var expression = new Expression();
            expression.init(logic[l].condition[i], context, opts)
            var res = expression.evaluate()
            if (trc) {
              trcObj.push(expression.getTrace())
            }
            if (Value.isError(res)) {
              Value.errorAdjustMessage(res, 'Error while evaluating form logic condition')
              this.endFormLogicException(logic[l], context, trcObj, Value.getErrorMessage(res), null, res, formData, lStartDate, lStart)
              return
            }
            if (res.type === 'variable') {
              res = Value.v(true, 'boolean')
            }
            res = Value.castToScalar(res, 'boolean')
            if (Value.isNullOrEmpty(res)) {
              this.endFormLogicException(logic[l], context, trcObj, 'Error while evaluating form logic condition \nExpression of form logic condition must evaluate to boolean, but result value is null or empty!', null, res, formData, lStartDate, lStart)
              return
            }
            if (!Value.isTrue(res)) {
              MCHistory.history(self, self.context.action, `FORM LOGIC: ${logic[l].name} on ${context['@event'].value}`, {'Input': Value.dataNode(context), 'Condition': trcObj.length == 0 ? null : {result: Value.toLiteral(res), trace: trcObj}, target: formData}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, logic: logic[l]})
              this.initLogicTimer(logic[l]);
              continue loopLogics;
            }
          } catch (ex) {
            this.endFormLogicException(logic[l], context, trcObj, 'Error while evaluating form logic condition \n' + ex.message, null, res, formData, lStartDate, lStart)
            return
          }
        }
      }
      if (['keyup', 'keydown'].indexOf(event) > -1) {
        options.e.preventDefault()
        options.e.stopPropagation()
      }
      if (logic[l].readsClipboard) {
        await this.setClipboardData(context)
      }
      let [input, trace] = self.mapToResultObject({mapping: logic[l].mapping}, {data: context, triggeredByField: triggeredByField, inForm: 'form'})
      if (!input) {
        this.endFormLogicException(logic[l], context, trcObj, trace[0], trace[1], res, formData, lStartDate, lStart)
        return
      }
      if (!Value.isNull(input)) {
        try { 
          this.setFormFields(formData, input)
        } catch (ex) {
          this.endFormLogicException(logic[l], context, trcObj, ex.message, trace, res, formData, lStartDate, lStart)
          return
        }
        rerender = true
      }
      MCHistory.history(self, self.context.action, `FORM LOGIC: ${logic[l].name} on ${context['@event'].value}`, {'Input': Value.dataNode(context), 'Condition': trcObj.length == 0 ? null : {result: Value.toLiteral(res), trace: trcObj}, 'Output': input, 'Trace' : trace, target: formData}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, logic: logic[l]})
      if (!MC.isNull(logic[l].actionLink)) {
        this.initActionStartTime()
        if (self.context.action.kind !== 'form') {
          return
        }
        logic[l].formExecutionId = this.formExecutionId
        resolveUnload = false
        let dRes = self.callLogicAction(logic[l], logicField)
        if (!MC.isNull(dRes)) {
          self.reactFlow().setState({dialog: {...dRes, triggeredByField: logicField, start: true, parentFlow: this, logic: logic[l], target: target ? target.node : null, lStart: lStart, lStartDate: lStartDate}})
        }
        if (dRes === undefined) {
          continue loopLogics
        }
      }
      var behavior = logic[l].behavior;
      if (!MC.isNull(logic[l].behavior)) {
        if ('submit' == behavior) {
          self.focusedOnFirst = false
          self.eventForm(logicField, 'beforesubmit')
        }
        self.reactFlow().validateByParent(behavior).then(() => {
          self.reactFlow().handleSubmitByParentChilds(behavior).then(() => {
            self.submitForm(logicField, behavior, logic[l].name, this.formExecutionId)
          }).catch(() => {})
        }).catch(() => { 
          self.reactFlow().forceUpdate() 
          self.initLogicTimer(logic[l]);
        })
        return
      } else if (MC.isNull(logic[l].actionLink)) {
        this.initLogicTimer(logic[l])
        if (!MC.isNull(logic[l].logic)) {
          for (let ltorun of MC.asArray(logic[l].logic)) {
            this.eventForm(null, null, target, null, {logic: formData.logic.find((item) => ltorun == item.name), triggerLogic: logic[l].name}).catch((error) => {
              this.endOperationException('SYS_MappingExc', error.message)
            })
          }
        }
      }
    }
    if (rerender) {
      this.reactFlow().setState({formData: formData})
    }
  }
  if (resolveUnload) {
    this.reactFlow().resolveUnload(formData)
  }
}

Flow.prototype.endFormLogicException = function(logic, context, trcObj, errMess, trace, res, formData, lStartDate, lStart) {
  MCHistory.history(this, this.context.action, `FORM LOGIC: ${logic.name} on ${context['@event'].value} - EXCEPTION END`, {'Input': Value.dataNode(context), 'Condition': trcObj.length == 0 ? null : {result: Value.toLiteral(res), trace: trcObj}, 'Output': Value.v(errMess), 'Trace' : trace, target: formData}, {start: lStartDate, end: Date.now(), duration: performance.now() - lStart, logic: logic})
  this.endOperationException('SYS_MappingExc', "Error while evaluating form logic '" + logic.name + "' \n" + errMess)
}

Flow.prototype.endDialog = function(output, message, notRerender, endAction) {
  let actionCode = this.reactFlow().state.dialog.actionCode
  let input = this.reactFlow().state.dialog.input 
  let dAction = this.reactFlow().state.dialog.action
  let dialogInState = this.reactFlow().state.dialog
  let logic = this.reactFlow().state.dialog.logic
  this.reactFlow().setState({dialog: null})
  if (!MC.isNull(message)) {
    this.endOperationException(output, message, input, null, null)
  } else if (!notRerender) {
    this.endEmbeddedDialog(actionCode, input, output, {triggeredByField: dialogInState.triggeredByField, submitParent: dAction?.submitParent}, logic, dialogInState.altMapping, endAction)
  }
}

Flow.prototype.closeDialog = function() {
  const actionCode = this.reactFlow().state.dialog.actionCode
  this.addToContext(this.context.data, actionCode, null)
  let submitOpts = null
  const dAction = this.reactFlow().state.dialog.action
  if (dAction.submitParent) {
    submitOpts = {}
    submitOpts.triggeredByField = this.reactFlow().state.dialog.triggeredByField
  }
  this.reactFlow().setState({dialog: null})
  if (submitOpts) {
    this.submitForm(submitOpts.triggeredByField, 'store', null, this.formExecutionId)
  }
}

Flow.prototype.endEmbeddedDialog = function(actionCode, input, output, submitOpts, logic, altMapping, endAction) {
  if (endAction.parentAction == 'none') {
    return
  }
  this.addToContext(this.context.data, actionCode, output)
  this.progressLazyForm(this.reactFlow().state.formData, {'Input': input, 'Output': output, triggeredByField: submitOpts.triggeredByField}, null, logic, altMapping)
  if (endAction.parentAction == 'refresh') {
    return
  }
  if (submitOpts.submitParent || endAction.parentAction) {
    this.handleSubmit(submitOpts.triggeredByField, endAction.parentAction || 'store')
  }
}

Flow.prototype.paginateForm = function(opts) {
  this.initActionStartTime()
  if (this.reactFlow().state.loader == 'all') {
    this.reactFlow().requestDimmer()
  } else {
    this.reactFlow().setState()
  }
  this.callAction(opts)
}

Flow.prototype.mapFormOutput = function(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRows, submitAction) {
  if (definition.formId) { // is form root
    let res = {}
    if (definition.param) {
      Object.assign(res, Value.fromJson(definition.param).value)
    }
    if (triggeredByField) {
      res['@submitTrigger'] = Value.v(triggeredByField.id)
      res['@submitTriggerPath'] = Value.v(this.getFormFieldPath(triggeredByField))
      if (!MC.isNull(repeaterRows)) {
        res['@submitTriggerIndex'] = Value.castToCollection(Value.fromJson(repeaterRows))
      }
    }
    if (submitAction) {
      res['@submitAction'] = Value.v(submitAction)
    }
    res['@submitBehaviour'] = Value.v(behaviour)
    if (this.reactFlow().containerRef.current) {
      res['@width'] = Value.v(this.reactFlow().containerRef.current.offsetWidth, 'decimal')
    }
    if (definition.data) {
      res.data = Value.extend(Value.dataNode({}), definition.data) // must be copied for for preserving values in log
    }
    contextTree = Value.dataNode(res)
  }
  if (definition.id === 'rows*') {
    let allRows = MC.getFieldParamBooleanValue(definition.parent.param, '@allRowsOutput')
    if (Array.isArray(repeaterRows) && (!Array.isArray(iterations) || iterations.length < repeaterRows.length) && !allRows) {
      let iterationsToPass = Array.isArray(iterations) ? repeaterRows.slice(0, iterations.length+1) : [repeaterRows[0]]
      this.mapFormOutputStep(definition.rows[iterationsToPass[iterationsToPass.length-1]], contextTree, triggeredByField, behaviour, iterationsToPass, repeaterRows)
    } else {
      if (Array.isArray(definition.rows) && definition.rows.length > 0) {
        for (var i=0; i<definition.rows.length; i++) {
          let iterationsToPass = Array.isArray(iterations) ? [...iterations, i] : [i]
          this.mapFormOutputStep(definition.rows[i], contextTree, triggeredByField, behaviour, iterationsToPass, true)
        }
      }
    }
  } else {
    this.mapFormOutputStep(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRows)
  }
  if (definition.formId) { // is form root
    return contextTree
  } 
}

Flow.prototype.setParamInFormOutput = function(value, prop, valueObj, iterations) {
  if ('@iteration' == prop || valueObj == null) { // internal helper parameter nad null should not be in form output
    return
  }
  valueObj = Value.fromJson(valueObj)
  if (Array.isArray(iterations) && Value.isCollection(value)) {
    if (Value.isNull(Value.collectionItem(value, iterations[iterations.length - 1]))) {
      value.value[iterations[iterations.length - 1]] = Value.dataNode({})
    }
    value.value[iterations[iterations.length - 1]].value[prop] = valueObj
  } else {
    value.value[prop] = valueObj
  }
}

Flow.prototype.mapFormOutputStep = function(definition, contextTree, triggeredByField, behaviour, iterations, repeaterRow) {
  for (let field of definition.fields) {
    let values = Value.dataNode({})
    let enabled = field.param && field.param['@enabled'] != false && field.param['@permitted'] != false
    if (field.fields && field.fields.length > 0 && enabled) {
      if (field.id === 'rows*') {
        if (!MC.isCorrespondingRepeater(field, triggeredByField)) {
          repeaterRow = null
        }
        values = {type: 'collection', value: []}
        this.mapFormOutput(field, values, triggeredByField, behaviour, iterations, repeaterRow, null)
      } else if (field.widget === 'dynamicPanel') {
        let dynamicOutput = Value.dataNode({})
        this.mapFormOutput(field, dynamicOutput, triggeredByField, behaviour, iterations, repeaterRow, null)
        values.value['fields'] = dynamicOutput
        values.value['fieldsData'] = dynamicOutput
      } else {    
        this.mapFormOutput(field, values, triggeredByField, behaviour, iterations, repeaterRow, null)
      }
    }
    if ('cancel' != behaviour && enabled) {
      if (field.id !== 'rows*') {
        for (let prop in field.param) {
          this.setParamInFormOutput(values, prop, field.param[prop], iterations)
        }
      } else {
        let allRows = MC.getFieldParamBooleanValue(field.parent.param, '@allRowsOutput')
        if (Array.isArray(repeaterRow) && !allRows) {
          const repeaterRowIndex = Array.isArray(iterations) ? repeaterRow.length - iterations.length : 0
          if (Array.isArray(field.rows)) {
            for (let prop in field.rows[repeaterRow[repeaterRowIndex]].param) {
              if (Value.isDataNode(Value.collectionItem(values, 0))) {
                let param = field.rows[repeaterRow[repeaterRowIndex]].param[prop]
                this.setParamInFormOutput(values, prop, param, [0])
              }
            }
          }
        } else {
          if (Array.isArray(field.rows)) {
            for (let r = 0; r < field.rows.length; r++) {
              for (let prop in field.rows[r].param) {
                let param = field.rows[r].param[prop]
                this.setParamInFormOutput(values, prop, param, [r])
              }
            }  
          }
        }
      }
      if (field.scriptedWidget && field.scriptedWidgetObject) {
        if (MC.isFunction(field.scriptedWidgetObject.getValue)) {
          var value = field.scriptedWidgetObject.getValue()
          for (let prop in value) {
            values.value[prop] = Value.fromJson(value[prop])
          }
        }
      }
    }
    if (!Value.isNull(values)) {
      if (definition.id !== 'rows*') {
        contextTree.value[field.id == 'rows*' ? 'rows' : field.id] = values
      } else {
        if (Array.isArray(iterations)) {
          let index = iterations[iterations.length - 1]
          if (Array.isArray(repeaterRow)) {
            index = 0
          }
          if (Value.isDataNode(contextTree)) {
            contextTree.type = 'collection'
            contextTree.value = []
          }
          if (Value.isNull(Value.collectionItem(contextTree, index))) {
            contextTree.value[index] = Value.dataNode({})
          }
          contextTree.value[index].value[field.id] = values
        }
      }
    }
  }
}

Flow.prototype.getFormFieldPath = function(field) {
  let path = null
  if (field) {
    path = field.id
    while (field.parent && !field.parent.formId) {
      field = field.id == 'rows*' ? field.parent.parent : field.parent
      path = field.id + '/' + path
    }
  }
  return path
}

Flow.prototype.setFormFields = function(definition, valueObj) {
  if (Value.isNull(valueObj)) {
    return
  }
  if (Value.isCollection(valueObj) && definition.id === 'rows*') {
    if (Array.isArray(definition.rows)) {
      if (Value.hasProperty(Value.collectionItem(valueObj, 0), '@positionPrev')) { // positions mode
        let rows = []
        let i = 0
        for (let item of Value.collectionValue(valueObj)) {
          let pos = Value.getProperty(item, '@positionPrev')    
          try {
            pos = parseInt(Value.castToScalar(pos, 'integer').value)
          } catch (e) {
            throw new Error('Parameter @positionPrev must be integer \n' + e.message)
          }
          let row = definition.rows[pos]
          if (definition.positionReorderMode) {
            if (!row) {
              let iToPass = definition.param['@iteration'] != undefined ? [...definition.param['@iteration'], i] : [i]
              row = MC.copyFormField({id: definition.id, fields: definition.fields, param: definition.param, flow: definition.flow}, iToPass)
              FieldDef.setProto(row)
            }
            this.setFormFields(row, item)
            rows.push(row)
            i++;
          } else {
            if (row) {
              this.setFormFields(definition.rows[pos], item)
            } else {
              if (isNaN(pos))  {
                throw new Error('Parameter @positionPrev must be not empty integer')
              } else {
                throw new Error(`Row with position ${pos} does not exist`)
              }
            }
          }
        }
        if (definition.positionReorderMode) {
          delete definition.positionReorderMode
          delete definition.parent.param['@positionReorderMode']
          definition.rows = rows
          MC.initParentFields(definition)
          MC.ensureIterations(MC.findRootRepeatable(definition.parent, definition.parent), []) // recalculate iterations
        }
        MC.recalculateRowsPosiiton(definition)
        return
      }
    } else {
      if (definition.positionReorderMode) { // cleaning in case that rows are empty but positionReorderMode was mapped
        delete definition.positionReorderMode
        delete definition.parent.param['@positionReorderMode']
      }
      definition.rows = []
    }
    let valSize = Value.collectionSize(valueObj)
    if (definition.rows.length > valSize) {
      definition.rows = definition.rows.slice(0, valSize)
    }
    for (let i=0;  i<valSize; i++) {
      if (!definition.rows[i]) {
        let iToPass = definition.param['@iteration'] != undefined ? [...definition.param['@iteration'], i] : [i]
        definition.rows[i] = MC.copyFormField({id: definition.id, fields: definition.fields, param: definition.param, flow: definition.flow}, iToPass)
        FieldDef.setProto(definition.rows[i])
      }
      this.setFormFields(definition.rows[i], Value.collectionItem(valueObj, i))
    }
    MC.initParentFields(definition)
    MC.recalculateRowsPosiiton(definition)
  } else {
    if (Value.isEmpty(valueObj) && definition.id === 'rows*' && definition.rows) { // deleting collection by empty
      definition.rows = []
      return
    }
    let wasDyanamicPanel = false
    for (let target in valueObj.value) {
      let value = Value.getProperty(valueObj, target)
      if (target == 'data') {
        definition.data = Value.extendFormData(definition.data || Value.dataNode({}), value)
        continue
      } else if (target == 'clipboardData') {
        if (Value.hasProperty(value, 'type') && Value.hasProperty(value, 'data')) {
          const type = Value.getProperty(value, 'type').value
          const blob = MC.base64ToBlob(Value.getProperty(value, 'data').value, type)
          const clipboardItem = new ClipboardItem({[type]: blob})
          navigator.clipboard.write([clipboardItem])
        } else if (Value.hasProperty(value, 'text')) {
          navigator.clipboard.writeText(Value.getProperty(value, 'text').value)
        }
      } else if (definition.widget == 'dynamicPanel' && (['fields', 'paramTree', 'fieldDefs', 'fieldsData'].indexOf(target) > -1)) { // DEPRECATED fields and paramTree
        if (!wasDyanamicPanel) {
          wasDyanamicPanel = true
          let fields = Value.hasProperty(valueObj, 'fields') ? Value.getProperty(valueObj, 'fields') : Value.getProperty(valueObj, 'fieldDefs') // DEPRECATED fields
          if (Value.isCollectionNotEmpty(fields)) {
            definition.fields = []
            for (let f of Value.collectionValue(fields)) {
              definition.fields.push(MC.extend({}, Value.toJson(f, true)))
            }
            MC.setFieldsPropertyRecusively(definition, 'flow', definition.flow)
            FieldDef.setProto(definition)
            MC.ensureIterations(definition, [])
            MC.initParentFields(definition)
          }
          if (Value.hasProperty(valueObj, 'paramTree')) { // DEPRECATED
            this.setFormFields(definition, Value.getProperty(valueObj, 'paramTree'))
          }
          if (Value.hasProperty(valueObj, 'fieldsData')) {
            this.setFormFields(definition, Value.getProperty(valueObj, 'fieldsData'))
          }
        }
        continue // to prevent overwriting fieldsData in output
      }
      let subField = null
      if (definition.fields) {
        subField = definition.fields.find(f => f.id == target || f.id == (target + '*'))
      }
      if (subField) {
        if (subField.id == "rows*" && Value.hasProperty(valueObj, '@positionReorderMode') && Value.isTrue(Value.getProperty(valueObj, '@positionReorderMode'))) {
          subField.positionReorderMode = true
        }
        this.setFormFields(subField, value)
      } else {
        if (definition.scriptedWidget) {
          definition.param[target] = Value.toJson(value, true)
        } else {
        if (Value.isDataNode(value) || Value.isCollection(value)) {
            definition.param = MC.extendFormField(definition.param, {[target]: Value.toJson(value, true)}) // must be wrapped in object with target to prevent converting root collection (like items*) to object with number keys
          } else if (!Value.isNull(value)) {
            if (Value.isEmpty(value) && Array.isArray(definition.param[target])) {
              delete definition.param[target]
            } else {
              if (target == '@positionPrev' && definition.id == 'rows*') { // ignoring @positionPrev in rows, to not be in form output, can not be deleted from valueObj beacuse must be preserver in log
                continue
              }
              let v = Value.toJson(value, true)
              if (target == 'value') {
                if (definition.basictype) {
                  v = Value.castToScalar(value, definition.basictype).value // automatic casting for example for datebox
                }
                if ((['integer', 'decimal'].indexOf(definition.basictype) > -1 || MC.getFieldParamValue(definition.param, '@formatType') == 'number') && !Value.isNullOrEmpty(value)) {
                  v = parseFloat(v)
                }
              }
              definition.param[target] = v
            }
          }
          MC.updateInvalidSummary(definition, false) // when is mapped invalid manually
        }
      }
    }
  }
}

Flow.prototype.callLogicAction = function(logic, triggeredByField, dialog) {
  if (this.context.action.kind !== 'form' || logic.formExecutionId && logic.formExecutionId != this.formExecutionId) {
    return
  }
  let action = null
  let altMapping = null
  let altMappingBack = null
  if (logic.actionLink) {
    if (Array.isArray(this.context.action.actionLink)) {
      action = this.context.action.actionLink.find(el => el.name == logic.actionLink)
      if (!action) {
        this.endOperationException('SYS_InvalidModelExc', "Data event with name '" +logic.actionLink + "' not found in action '" + this.context.action.code + "'!")
        return
      }
      if (action && action.nextaction) {
        altMapping = action.altmapping
        altMappingBack = action.altmappingback
        action = this.getActionById(action.nextaction)
      }
    }
  }
  if (action.kind != 'dialog' && action.kind != 'call') {
    this.endOperationException('SYS_InvalidModelExc', "Action '" + action.code + "' is not dialog or call action!")
    return
  }
  if (action.kind == 'dialog' || dialog) {
    let flowName = action.calls
    if (!flowName) {
      this.endOperationException('SYS_InvalidModelExc', "Action '" + logic.actionLink + "' calls no operation!")
      return
    }
    let [input, trace] = this.mapToResultObject(this.selectMapping(action, altMapping), {data: this.context.data, triggeredByField: triggeredByField, inForm: this.context.action.code, logicName: logic.name})
    if (!input) {
      this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
      return
    }
    return {flowName: flowName, input: input, size: action.dialogSize ? action.dialogSize : null, trace: trace, altMapping: altMappingBack, actionCode: action.code, action: action, asContext: action.asContext, cssClass: action.cssclass, closeIcon: action.closeIcon, position: action.dialogPosition}
  } else {
    return this.callAction({action: action, altMappingBack: altMappingBack, altMapping: altMapping, triggeredByField: triggeredByField}, logic)
  }
};

Flow.prototype.callAction = function(options, logic) {
  let action = this.context.action
  if (options) {
    action = options.action
    if (MC.isNull(action)) {
      this.endOperationException('SYS_InvalidModelExc', "Lazy data action with code '" + options.code + "' not found!")
      return
    }
  }
  if (!action.calls) {
    this.endOperationException('SYS_InvalidModelExc', "Call action '" + action.code + "' calls no operation, not supported!")
    return
  }
  let input = null
  let trace = null
  if (action.multiCall && this.multiInput && this.multiInput.length > 0) {
    input = this.multiInput.shift()
    this.initActionIterationStartTime()
  } else {
    let context = {data: this.context.data}
    let altMapping = this.context.altmapping
    if (options) {
      altMapping = options.altMapping
      context.triggeredByField = options.triggeredByField
      context.inForm = this.context.action.code
      context.logicName = logic?.name
    }
    [input, trace] = this.mapToResultObject(this.selectMapping(action, altMapping), context)
  }
  if (input) {
    if (action.leaveFlow) {
      this.leaveOperation(action.calls, input, trace)
    } else {
      if (action.multiCall) {
        if (!this.multiInput) {
          let inputProp = Value.getProperty(input, 'input')
          if (!Value.isNull(inputProp) && Value.isCollectionNotEmpty(inputProp)) {
            this.multiInputOrig = input
            this.multiInput = [...Value.collectionValue(inputProp)]
            this.multiTrace = trace
            input = this.multiInput.shift()
            this.initActionIterationStartTime()
          } else {
            this.addToContext(this.context.data, action.code, null)
            this.progress({'Input': input, 'Trace': trace})
            return
          }
        }
      }
      const flow = new Flow()
      flow.init().setLang(this.lang).setConfPath(this.confPath).setParentFlow(this).setBaseUrl(this.baseUrl)
      flow.setFlowConfiguration(this.confPath, action.calls)
      if (!action.interface.isFrontend) {
        flow.setServerSide('server', action.interface)
      }
      if (MC.isFunction(this.afterRenderForm)) {
        flow.setAfterRenderFormFunction(this.afterRenderForm)
      }
      if (action.interface.cache) {
        flow.setCacheable()
      }
      flow.setEnv(this.context.data.env)
      flow.setWantedLogLevel(this.wantedLogLevel)
      flow.setContextCallActionParent(action)
      flow.loadAndStart(input, trace).then((opts) => {
        if (opts.leaveFlow) {
          let {calls, input, trace} = {...opts}
          this.leaveOperation(calls, input, trace)
        } else if (options) {
          this.addToContext(this.context.data, action.code, opts.output)
          opts.opts.triggeredByField = options.triggeredByField
          this.progressLazyForm(this.formData ? this.formData : this.reactFlow().state.formData, opts.opts, action, logic, typeof options != 'string' ? options.altMappingBack : null)
        } else {  
          let output = opts.output
          let logObject = opts.opts
          if (action.multiCall) {
            if (!this.multiOutput) {
              this.multiOutput = []
            }
            this.multiOutput.push(output)
            MCHistory.history(this, this.context.action, 'MULTICALL(' + this.multiOutput.length + ')', {'Input': input, 'Output': output, 'Server log': opts.opts['Server log'], 'Trace': this.multiTrace, executionId: flow.instanceId, target: flow.flow, takenFromCache: flow.takenFromCache}, {start: this.context.actionIterationStartDate, end: Date.now(), duration: performance.now() - this.context.actionIterationStart})
            if (this.multiInput.length > 0) {
              this.callAction()
              return
            } else {
              output = Value.dataNode({'output': Value.v(this.multiOutput, 'collection')})
              logObject['Output'] = output
              this.multiInput = null
              this.multiOutput = null
              logObject['Trace'] = this.multiTrace
              this.multiTrace = null
              logObject['Input'] = this.multiInputOrig
              this.multiInputOrig = null
              delete logObject['Server log']
              delete logObject.executionId
              delete this.context.actionIterationStart
              delete this.context.actionIterationStartDate
            }
          }
          this.addToContext(this.context.data, action.code, output)
          this.progress(logObject)
        }
      }).catch((args) => {
        let [type, message, exception, log] = [...args]
        if (options) { // form event
          let opts = log.opts
          delete log.opts
          MCHistory.history(this, action, 'EXCEPTION END', {Input: input, Output: Value.fromJson(exception), Trace: trace, 'Server log': log, target: opts.target, executionId: opts.executionId}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
          this.endOperationException(type, message, null, exception, null, null)
        } else {
          this.endOperationException(type, message, input, exception, log, trace)
        }
      })
    }
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return undefined
  }
}

Flow.prototype.runOnServer = function() {
  let input = Value.toJson(this.input)
  if (input) {
    MC.replaceValues(input, "", null)
  }
  if (!this.parentFlow) {
    MCHistory.history(this, null, 'OPERATION START', {'Input': this.input}, {end: this.opStartDate})
  }
  if (!this.reactFlow()) {
    return
  }
  let url = this.baseUrl + ReactFlow.flowServerUrl + MC.noTrailingSlash(this.confPath) + '/' + this.flowName + '?language=' + this.lang
  if (this.wantedLogLevel) {
    url += '&includeid=true'
    if (this.wantedLogLevel !== 'AUTO') {
      url += '&loggingthreshold=' + this.wantedLogLevel
    }
  }
  let method = 'POST';
  let content = input ? JSON.stringify(input) : null
  if (this.cache) {
    method = 'GET'
    if (content) {
      url += (url.indexOf('?') > 0 ? '&' : '?') + 'inputdata=' + encodeURIComponent(content)
    }
    content = null
  }
  let self = this
  MC.callServer(method, url, MC.getJsonType(), content, MC.getJsonType()).then(function (res) {
    try {
      var output = {};
      var content = res.content;
      var log = {}
      if (content) {
        try {
          output = JSON.parse(content);
        } catch (e) {
          self.endOperationException('SYS_IntegrationExc', 'Invalid server response: ' + content, Value.fromJson(input), null, null, self.inputMapTrace)
          return
        }
        if (!MC.isNull(output['fl:flowId']) && !MC.isNull(output['fl:flowLogId'])) {
          log = {flowId: output['fl:flowId'], flowLogId: output['fl:flowLogId']}
        }
      }
      log.flowConfiguration = self.confPath
      log.requestId = res.headers['x-metada-request-id']
      if (res.status == 200 || res.status == 204) {
        if (!MC.isNull(output['fl:flowId']) && !MC.isNull(output['fl:flowLogId'])) { // deleting only for ok response, for bad will be stored into env.exception
          delete output['fl:flowId']
          delete output['fl:flowLogId']
        }
        MC.replaceValues(output, null, "")
        output = MC.isEmptyObject(output) ? null : output
        self.endOperation(Value.fromJson(output), log)
      } else {
        var type = 'SYS_IntegrationExc';
        if (output.errorName) {
          type = output.errorName;
        }
        let message = 'Calling server flow failed for url ' + url  + '! Status:' + res.status;
        if (output.errorMessage) {
          message = Array.isArray(output.errorLocation) ? output.errorLocation.join('\n') : ""
          message = message + '\n' + output.errorMessage
        }
        self.endOperationException(type, message, Value.fromJson(input), output, log, self.inputMapTrace)
        return
      }
    } catch (e) {
      self.endOperationException('SYS_IntegrationExc', e.message, Value.fromJson(input), null, null, self.inputMapTrace)
      return
    }
  }).catch(function (err) {
    if (navigator.onLine) {
      self.endOperationException('SYS_IntegrationExc', 'Calling server flow failed for url ' + url + ': ' + err.message, Value.fromJson(input), null, null, self.inputMapTrace)
      return
    } else {
      self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available for url ' + url + ': ' + err.message, Value.fromJson(input), null, null, self.inputMapTrace)
      return
    }
  });
};

Flow.prototype.convertOutputTreeData = function(outputTree, valueTree) {
  try {
    let res = {}
    if (Value.isCollectionNotEmpty(valueTree)) {
      valueTree = Value.getFirstNotNull(valueTree)
    }
    for (let param of outputTree.output) {
      let key = param.name
      if (MC.isNull(key)) {
        if (param.basictype == 'anyType' && !MC.isNull(valueTree)) {
          MC.extend(res, valueTree.value)
        }
      } else {
        if (key.endsWith('*')) {
          key = key.substring(0, key.length-1)
        }
        let inValues = Value.getProperty(valueTree, key)
        if (!Value.isNull(inValues)) {
          let values, err
          if (param.name.endsWith('*')) {
            inValues = Value.castToCollection(inValues)
            values = []
            for (let ivalue of Value.collectionValue(inValues)) {
              if (!ivalue) continue // sometimes extend produce sparse collection, at this time this remove possible exception
              let value
              if (param.output && !Value.isEmpty(ivalue)) {
                [value, err] = this.convertOutputTreeData(param, ivalue)
                if (!value) {
                  return [value, err]
                }   
              } else {
                try {
                  value = (param.basictype == 'anyType') ? ivalue : Value.castToScalar(ivalue, param.basictype)
                } catch (e) {
                  return [false, 'Error building ouput - parameter: ' + param.name + ', value: ' + Value.toLiteral(ivalue) + ', message: ' + e.message]
                }
              }
              if (!Value.isNull(value)) {
                values.push(value)
              }
            }
            values = Value.v(values, 'collection')
          } else {
            if (Value.isCollection(inValues)) {
              inValues = Value.getFirstNotNull(inValues)
            }
            if (param.output && !Value.isEmpty(inValues)) {
              [values, err] = this.convertOutputTreeData(param, inValues)
              if (!values) {
                return [values, err]
              }
            } else {
              try {
                values = (param.basictype == 'anyType') ? inValues : Value.castToScalar(inValues, param.basictype)
              } catch (e) {
                return [false, 'Error building ouput - parameter: ' + param.name + ', value: ' + Value.toLiteral(inValues) + ', message: ' + e.message]
              }
            }
          }
          if (!Value.isNull(values)) {
            res[key] = values
          }
        } else {
          if (key === '@' && !Value.isDataNode(valueTree)) { // serialize to xml node
            res['@'] = Value.castToScalar(valueTree, param.basictype)
          } else if (param.mandat) {
            return [false, 'Error building ouput - output parameter "' + param.name + '" must have value!']
          }
        }
      }
    }
    return [MC.isEmptyObject(res) ? Value.v(null) : Value.dataNode(res), null]
  } catch (e) { 
    return [false, 'Error building ouput, message: ' + e.message]
  }
}

Flow.prototype.decisionAction = function() {
  try {
    var action = this.context.action;
    if (!action.branch) {
      this.endOperationException('SYS_InvalidModelExc', 'Decision action "' + action.code + '" must have at least one branch defined!');
      return;
    }
    var testedBranches = {}
    for (var i = 0; i < action.branch.length; i++) {
      var branch = action.branch[i];
      var passed = true;
      let trc = this.debug('TRACE')
      let opts = {function: this.flow.function, trace: trc}
      testedBranches[branch.name] = {}
      if (branch.expr && Array.isArray(branch.expr)) {
        let res
        for (var e = 0; e < branch.expr.length; e++) {
          var expression = new Expression()
          expression.init(branch.expr[e], this.context.data, opts)
          res = expression.evaluate()
          if (Value.isError(res)) {
            Value.errorAdjustMessage(res, `Error while evaluating decision branch '${branch.name}'`)
            MC.error(Value.getErrorMessage(res))
          }
          if (res.type === 'variable') {
            res = Value.v(true, 'boolean')
          }
          if (trc) {
            if (!testedBranches[branch.name]['trace']) {
              testedBranches[branch.name]['trace'] = []
            }
            testedBranches[branch.name]['trace'].push(expression.getTrace())
          }
          res = Value.castToScalar(res, 'boolean')
          if (Value.isNullOrEmpty(res)) {
            this.endOperationException('SYS_MappingExc', 'Expression of decision action condition in branch "' + branch.name + '" must evaluate to boolean, but result value is null or empty!', null, null, null, {'Tested branches': testedBranches})
            return
          }
          if (Value.isFalse(res)) {
            passed = false
            break
          }
        }
      }
      testedBranches[branch.name]['result'] = passed
      if (passed) {
        if (!branch.nextaction) {
          this.endOperationException('SYS_InvalidModelExc', 'Branch "' + branch.name + '" of decision action "' + action.code + '" must have next action defined!');
          return;
        }
        this.context.nextAction = this.getActionById(branch.nextaction)
        this.context.nextAltmapping = branch.altmapping
        var result = {};
        result['branch'] = branch.name;
        result['action'] = this.context.nextAction.code;
        this.progress({'Result': result, 'Tested branches': testedBranches});
        return;
      }
    }
    this.endOperationException('SYS_MappingExc', 'No branch of decision action "' + action.code + '" passed!');
    return;
  } catch (ex) {
    this.endOperationException('SYS_MappingExc', ex.message);
  }
};

Flow.prototype.endAction = function() {
  let [output, trace] = this.mapToResultObject(this.selectMapping(this.context.action, this.context.altmapping), this.context)
  if (output) {
    MCHistory.history(this, this.context.action, null, {'Output': output, 'Trace' : trace}, {start: this.context.actionStartDate, end: Date.now(), duration: performance.now() - this.context.actionStart})
    if (this.context.action.throwsException) {
      let mess = "End action exception"
      if (Value.isDataNode(output)) {
        if (output.value['sys:errorCode']) {
          mess += ' [' + Value.toJson(output.value['sys:errorCode']) + ']'
        }
        if (output.value['sys:errorMessage']) {
          mess += ': ' + Value.toJson(output.value['sys:errorMessage'])
        }
      }
      this.endOperationException(this.context.action.throwsException.name, mess)
    } else {
      this.endOperation(output, null, this.context.action.redirect)
    }
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return
  }
}

Flow.prototype.selectMapping = function(action, altMappingName) {
  if (altMappingName) {
    if (Array.isArray(action.altMapping)) {
      for (let mapping of action.altMapping) {
        if (mapping.name == altMappingName) {
          return {name: altMappingName, mapping: mapping.mapping}
        }
      }
    }
    return {exception: 'Alternative mapping with name ' + altMappingName + ' not found in acion "' + action.code + '"!'}
  } else {
    return {mapping: action.mapping}
  }
}

Flow.prototype.preparePathToRedirect = function(output) {
  let path = output.path
  if (!MC.isNull(path)) {
    if (path.startsWith('/')) {
      path = path.substring(1)
    }
    let sep = path.indexOf('?') > -1 ? '&' : '?'
    if (output.parameters && MC.isPlainObject(output.parameters)) { 
      for (let key in output.parameters) {
        if (output.parameters.hasOwnProperty(key)) {
          path += sep + key + '=' + encodeURIComponent(output.parameters[key])
        }
        sep = '&'
      }
    }
    return path
  } else {
    this.endOperationException('SYS_MappingExc', 'Output of redirect operation not contains path!', null, output, null, null)
  }
}

Flow.prototype.endOperation = function(unorderedOutput, log, redirect) {
  let output = {}, erMsg
  if (redirect || this.serverSide || unorderedOutput && this.takenFromCache) {
    output = unorderedOutput
  } else if (unorderedOutput && this.flow.output) {
    [output, erMsg] = this.convertOutputTreeData(this.flow, unorderedOutput)
    if (!output) {
      this.endOperationException('SYS_ValidationExc', erMsg, unorderedOutput, null, null, null)
      return false
    }
  }
  if (redirect && this.reactFlow()) {
    const npath = this.preparePathToRedirect(Value.toJson(output))
    this.reactFlow().routeTo(null, npath)
  } else if (!this.parentFlow) {
    if (!this.flow.action || this.serverSide) {
      let trace = ['decisiontable', 'ruleset'].indexOf(this.flow.kind) >= 0 ? log : null
      log = ['decisiontable', 'ruleset'].indexOf(this.flow.kind) >= 0 ? null : log
      MCHistory.history(this, null, 'OPERATION END', {'Output': output, 'Server log': log, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart});
    }
    this.resolve({output: output, opts: {endAction: this.context && this.context.action ? this.context.action : {}}})
  } else {
    if (this.cacheable && !this.serverSide && !this.takenFromCache) {
      MCCache.put(this.cacheKey(), output)
    }
    this.parentFlow.adjustNsMap(Value.getProperty(this.context.data.env, 'ns'))
    if (['framework', 'function', 'decisiontable'].indexOf(this.flow.kind) > -1) {
      let trace = this.flow.kind == 'decisiontable' ? log : this.inputMapTrace
      let executionId = null
      let target = null
      if (log?.clientExecutionId) {
        executionId = log.clientExecutionId
        target = log.target
        log = null
      }
      MCHistory.history(this, {kind: 'framework', code: this.flow.id, id: this.flow.rbsid}, 'OPERATION', {'Input': this.input, 'Output': output, 'Trace': trace, 'Server log': log, executionId: executionId, target: target}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
      log = null
    }
    let executionId = this.instanceId
    log = this.flow.kind == 'decisiontable' ? null : log
    this.context.data = {}
    this.resolve({output: output, opts: {'Input': this.input, 'Output': output, 'Server log': log, 'Trace': this.inputMapTrace, executionId: executionId, target: this.flow, takenFromCache: this.takenFromCache}})
  }
}

Flow.prototype.endOperationException = function(type, message, input, output, log, trace = this.inputMapTrace) {
  if (!this.context.action) {
    input = (input && !Value.isNull(input)) ? input : (this.input && !Value.isNull(this.input)) ? this.input : null
  }
  this.isRenderingInterupted = true
  this.clearLogicTimers();
  if (!MC.isPlainObject(type)) {
    type = this.buildExceptionTypeObject(type)
  }
  if (this.flow) {
    if (this.context.action) {
      message = "operation '" + this.flow.id + "' / action '" + this.context.action.code + "' \n" + message
    } else {
      message = "operation '" + this.flow.id + "' / " + message
    }
  }
  let exception = {}
  exception.errorName = type.name
  exception.errorMessage = message
  exception.errorCode = type.name
  exception.errorKind = type.kind
  if (!MC.isNull(output)) {
    exception = output
  }
  let executionId, target
  if (log) {
    if (log.requestId) {
      exception.serverRequestId = log.requestId
      delete log.requestId
    }
    if (log.opts) {
      executionId = log.opts.executionId
      target = log.opts.target
      delete log.opts
    }
  } else {
    log = {}
  }
  if (!this.context.data.env) {
    this.context.data.env = Value.dataNode({})
  }
  this.context.data.env.value.exception = Value.fromJson(exception)
  let relevantException = false;
  if (this.context.action && this.context.action.exception) {
    relevantException = this.getRelevantException(type, this.context.action.exception);
  }
  if (relevantException) {
    MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
    this.context.nextAction = this.getActionById(relevantException.nextaction);
    this.context.nextAltmapping = relevantException.altmapping
    this.isRenderingInterupted = false
    this.progress({'Input': input, 'Output': Value.fromJson(exception), 'Trace': trace, 'Server log': log, isException: true, exceptionHandled: true, target: target, executionId: executionId})
  } else {
    var startAction = null;
    if (this.flow && this.flow.action) {
      startAction = this.getStartAction();
    }
    let isThrowedFromEnd = false;
    if (this.context.action && this.context.action.kind === 'end' && this.context.action.throwsException && this.context.action.throwsException.name === type.name) {
      isThrowedFromEnd = true;
    }
    relevantException = false;
    if (startAction && startAction.exception) {
      relevantException = this.getRelevantException(type, startAction.exception);
    }
    if (!isThrowedFromEnd && relevantException) {
      MCHistory.log(MCHistory.T_EXCEPTION, type.name + ': ' + message, this.debug('BASIC'));
      this.context.nextAction = this.getActionById(relevantException.nextaction);
      this.context.nextAltmapping = relevantException.altmapping
      this.isRenderingInterupted = false
      this.progress({'Input': input, 'Output': Value.fromJson(exception), 'Trace': trace, 'Server log': log, isException: true, isException: true, exceptionHandled: true, target: target, executionId: executionId})
    } else {
      if (!this.parentFlow) {
        MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': Value.fromJson(exception), 'Server log': log, 'Trace': trace, isException: true, target: target, executionId: executionId}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
        this.reject([type.name, message])
      } else {
        if (this.flow && this.flow.action && !this.flow.mock) {
          MCHistory.history(this, this.context.action, 'EXCEPTION END', {'Input': input, 'Output': Value.fromJson(exception), 'Server log': {...log}, 'Trace': trace, isException: true, target: target, executionId: executionId}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
          delete log.flowLogId
          delete log.flowId
          delete log.flowConfiguration
        }
        if (this.parentFlow.context && this.parentFlow.context.action && this.parentFlow.context.action.multiCall) {
          this.parentFlow.multiInput = null
          this.parentFlow.multiOutput = null
          this.parentFlow.multiTrace = null
          this.parentFlow.multiInputOrig = null
        }
        log.opts = {executionId: this.instanceId, target: this.flow}
        this.reject([type, message, exception, log])
      }
    }
  }
}

Flow.prototype.buildExceptionTypeObject = function(type) {
  let exception = {name: type, kind: 'technical'}
  exception.parent = []
  if (this.flow && this.flow.exception && this.flow.exception[type]) {
    let curr = this.flow.exception[type]
    while (!MC.isNull(curr.parent)) {
      exception.parent.push(curr.parent)
      if ('SYS_BusinessExc' == curr.parent) {
        exception.kind = 'business'
      }
      curr = this.flow.exception[curr.parent]
    }
  } else {
    exception.parent.push('SYS_RootExc')
  }
  return exception
}

Flow.prototype.getRelevantException = function(thrown, caughtArr) {
  if (Array.isArray(caughtArr) && caughtArr.length > 0) {
    for (let caught of caughtArr) {
      if (thrown.name == caught.name || Array.isArray(thrown.parent) && thrown.parent.indexOf(caught.name) > -1) {
        return caught;
      }
    }
  }
  return false;
};

Flow.prototype.leaveOperation = function(calls, input, trace) {
  MCHistory.history(this, this.context.action, 'LEAVE FLOW', {'Output': input, 'Trace': trace}, {start: this.opStartDate, end: Date.now(), duration: performance.now() - this.opStart})
  this.resolve({leaveFlow: true, calls: calls, input: input, trace: trace})
}

Flow.prototype.transformAction = function() {
  let action = this.context.action
  let [input, trace] = this.mapToResultObject(this.selectMapping(action, this.context.altmapping), this.context)
  if (input) {
    let [output, erMsg] = this.convertOutputTreeData(action, input)
    if (!output) {
      this.endOperationException('SYS_ValidationExc', erMsg, input, null, null, trace)
      return false
    }
    this.addToContext(this.context.data, action.code, output)
    this.progress({'Input': input, 'Output': output, 'Trace' : trace})
  } else {
    this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
    return
  }
}

Flow.prototype.feedbackAction = function() {
  try {
    var action = this.context.action;
    var feedback = MC.extend({}, action.feedback);
    if (MC.isNull(feedback)) {
      this.endOperationException('SYS_InvalidModelExc', "Feedback action '" + action.code + "' has no feedback assigned!");
      return;
    }
    let result = {};
    let trace;
    if (!MC.isNull(action.mapping)) {
      [result, trace] = this.mapToResultObject(this.selectMapping(action, this.context.altmapping), this.context)
      if (!result) {
        this.endOperationException('SYS_MappingExc', trace[0], null, null, null, trace[1])
        return
      }
      feedback.param = Value.toJson(result)
      feedback.lang = this.lang
      feedback.title = MC.formatValue('', 'message', null, feedback.title, feedback)
      feedback.help = MC.formatValue('', 'message', null, feedback.help, feedback)
    }
    let rootFlow = this.getRootFlow()
    if (!Array.isArray(rootFlow.context.feedback)) {
      rootFlow.context.feedback = []
    }
    rootFlow.context.feedback.push(feedback)
    this.progress({'Input': result, 'Trace' : trace})
  } catch (e) {
    this.endOperationException('SYS_MappingExc', e.message, null, null, null, null)
  }
}

Flow.prototype.mapToResultObject = function(mappingObj, context) {
  if (mappingObj.exception) {
    return [false, [mappingObj.exception, null]]
  }
  let result = Value.v(null)
  let traceObj = {}
  let trc = this.debug('TRACE')
  if (mappingObj.name && trc) {
    traceObj['Used alternative mapping'] = mappingObj.name
  }
  if (mappingObj.mapping && Array.isArray(mappingObj.mapping)) {
    let opts = {function: this.flow.function, trace: trc}
    if (context.inForm) {
      opts.inForm = context.inForm
      opts.flow = this
      opts.triggeredByField = context.triggeredByField
      opts.logicName = context.logicName
    }
    for (let mapping of mappingObj.mapping) {
      if (MC.isEmptyObject(mapping)) {
        continue
      }
      try {          
        let expression = new Expression()
        expression.init(mapping, context.data, opts)
        let valueObj = expression.evaluate()
        if (trc) {
          const newTrace = expression.getTraceAsPaths()
          for (const path in newTrace) {
            if (traceObj[path]) {
              if (!Array.isArray(traceObj[path])) {
                traceObj[path] = [traceObj[path]]
              }
              traceObj[path].push(newTrace[path])
            } else {
              traceObj[path] = newTrace[path]
            }
          }
        }
        if (Value.isError(valueObj)) {
          return [false, [Value.getErrorMessage(valueObj), traceObj]]
        }
        if (!Value.isNull(valueObj)) {
          valueObj = MC.makeObjectRecursive(valueObj)
          if (!Value.isNull(valueObj)) {
            if (Value.isNull(result)) {
              result = valueObj
            } else {
              Value.extend(result, valueObj)
            }
          }
        }
      } catch (ex) {
        return [false, [ex.message, traceObj]]
      }
    }
  }
  return [result, traceObj]
}

Flow.prototype.getMockData = function() {
  try {
    let operation = this.flow
    if (operation.output) {
      if (Array.isArray(operation.mock) && operation.mock.length > 0) {
        // mapping into input for evaluating conditions
        for (let i=0; i<operation.mock.length; i++) {
          let passed = true
          if (operation.mock[i].expr) {
            let expression = new Expression()
            expression.init(operation.mock[i].expr[0], {input: this.input}, {trace: this.debug('TRACE')})
            let res = expression.evaluate()
            if (Value.isError(res)) {
              MC.error(Value.getErrorMessage(res))
            }
            if (res.type !== 'variable') {
              if (Value.isFalse(Value.castToScalar(res, 'boolean'))) {
                passed = false
              }
            }
          }
          if (passed) {
            return Value.fromJson(MC.xmlStringToObject(operation.mock[i].data))
          }
        }
      }
    }
  } catch (ex) {
    this.endOperationException('SYS_MappingExc', ex.message)
  }
  return false
}

Flow.prototype.runMock = function() {
  if (this.flow.mock && this.getCfgParameter("fl:useMocks") == "true") {
    let mockOutput = this.getMockData()
    if (mockOutput !== false) {
      this.endOperation(mockOutput)
      return true
    }
  }
  return false
}  

Flow.prototype.runFunctionOperation = function() {
  var type = this.flow.functiontype;
  if (type == 'javascript') {
    if (!this.flow.functioncode) {
      this.endOperationException('SYS_InvalidModelExc', 'Code of function operation ' + this.flow.id + ' can not be empty!');
    }
    var input = Value.toJson(this.input) || {}
    var output = {};
    eval(this.flow.functioncode);
    this.endOperation(Value.fromJson(output))
  } else {
    this.endOperationException('SYS_InvalidModelExc', 'Unsupported function type: ' + type)
  }
}

Flow.prototype.runFrameworkOperation = function() {
  let input
  if (this.flow.isFrontend && this.flow.input && Array.isArray(this.flow.input)) {
    input = this.convertInputTreeData(this.flow, this.input)
    if (!input) {
      return
    }
  }
  if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.flow.id) == -1) {
    input = Value.toJson(input) || {}
  }
  let self = this
  if (this.flow.id == 'BRWS_ExternalUrlWindowOpen') {
    var url = input.url;
    if (!MC.isNull(input.params) && MC.isPlainObject(input.params)) {
      for (var param in input.params) {
        url += (url.indexOf('?') < 0 ? '?' : '&') + param + '=' + input.params[param];
      }
    }
    var target = input.target;
    if (target == 'blank') {
      window.open(url);
      this.endOperation(Value.v(null))
    } else if (target == 'top') {
      window.top.location.href = url;
    } else if (target == 'parent') {
      window.parent.location.href = url;
    } else {
      this.reactFlow().routeTo(null, url)
    }
  } else if (this.flow.id == 'BRWS_DocumentTitleSet') {
    if (!MC.isNull(input.title)) {
      if (input.target == 'top') {
        window.top.document.title = input.title
      } else if (input.target == 'parent') {
        window.parent.document.title = input.title
      } else {
        document.title = input.title
      }
    }
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'FWK_EventSend') {
    var urlarr = window.location.href.split("/");
    if (input.iframeId) {
      document.getElementById(input.iframeId).contentWindow.postMessage(input, urlarr[0] + "//" + urlarr[2])
    } else if (input.parent === true && parent) {
      parent.postMessage(input, urlarr[0] + "//" + urlarr[2]);
    } else {
      window.postMessage(input, urlarr[0] + "//" + urlarr[2]);
    }
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'FWK_EnvironmentRefresh') {
    let envOp = this.getCfgParameter('fl:environmentOperation')
    if (envOp) {
      MC.getEnvironmentContext(this.confPath, envOp, self.baseUrl, self.reactFlow().props.mconf.lang).then(function(context) {
        self.updateEnvContext(context)
        self.endOperation(Value.v(null))
      }).catch(function (err) {
        if (navigator.onLine) {
          self.endOperationException('SYS_IntegrationExc', 'Reading environment context failed: ' + err)
          return
        } else {
          self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available: ' + err)
          return
        }
      })  
    } else {
      self.updateEnvContext(null)
      self.endOperation(Value.v(null))
    }
  } else if (this.flow.id == 'FWK_CacheClear') {  
    MCCache.clear()
    self.endOperation(Value.v(null))
  } else if (['BRWS_LocalDataStore', 'BRWS_LocalDataGet', 'BRWS_LocalDataList', 'BRWS_LocalDataDelete'].indexOf(this.flow.id) > -1) {
    let res;
    switch (this.flow.id) {
      case 'BRWS_LocalDataStore': res = MCBrws.store(input.path, input.data); break;
      case 'BRWS_LocalDataGet': res = MCBrws.get(input.path); break;
      case 'BRWS_LocalDataList': res = MCBrws.list(input.path); break;
      case 'BRWS_LocalDataDelete': res = MCBrws.delete(input.path); break;
    }
    if (res.error) {
      this.endOperationException('SYS_InvalidModelExc', res.error);
    } else {
      this.endOperation(Value.fromJson(res.result))
    }
  } else if (this.flow.id == 'BRWS_ResizeImage') {
    let quality = 0.75;
    if (input.quality && MC.isNumeric(input.quality) && input.quality >= 0 && input.quality <= 1) {
      quality = parseFloat(input.quality);
    }
    let maxWidth = 800;
    if (input.maxWidth && MC.isNumeric(input.maxWidth) && input.maxWidth > 0) {
      maxWidth = parseInt(input.maxWidth);
    }
    let maxHeight = 600;
    if (input.maxHeight && MC.isNumeric(input.maxHeight) && input.maxHeight > 0) {
      maxHeight = parseInt(input.maxHeight);
    }
    let image = new Image();
    image.onload = function() {
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, width, height);
      let output = {contentType: "image/jpeg"};
      let data = canvas.toDataURL('image/jpeg', quality);
      output.data = data.substring(data.indexOf(';base64,')+8);
      self.endOperation(Value.fromJson(output))
    };
    image.src = 'data:' + input.contentType + ';base64,' + input.data;
  } else if (['FWK_OperationExecute_FE', 'FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.flow.id) > -1) {
    let operationIn = Value.getProperty(input, 'input')
    input = Value.toJson(input)
    let confPath = this.confPath
    if (['FWK_OperationWithConfigExecute_FE', 'FWK_OperationWithApplicationExecute_FE'].indexOf(this.flow.id) > -1) {
      confPath = input.flowConfig
    }
    const flow = new Flow()
    flow.init().setLang(self.lang).setConfPath(confPath).setParentFlow(self).setFlowConfiguration(confPath, input.operation)
    let newBase = this.baseUrl
    if ('FWK_OperationWithApplicationExecute_FE' == this.flow.id) {
      newBase = input.appUiRi
    } else if ('FWK_OperationWithConfigExecute_FE' == this.flow.id) {
      newBase = '/in/miniclient/ui/'
    }
    flow.setBaseUrl(newBase)
    if (MC.isFunction(self.afterRenderForm)) {
      flow.setAfterRenderFormFunction(self.afterRenderForm)
    }
    if (input.executionId) {
      flow.setInstanceId(input.executionId)
    }
    if ('FWK_OperationExecute_FE' == this.flow.id) {
      flow.setEnv(this.context.data.env)
    }
    flow.setServerSide(input.executeOn)
    flow.setWantedLogLevel(this.wantedLogLevel)
    flow.loadAndStart(operationIn, null).then((opts) => {
      if (opts.leaveFlow) {
        let {calls, input, trace} = {...opts}
        this.leaveOperation(calls, input, trace)
      } else {
        let log = null
        let executionId = flow.instanceId 
        if (flow.serverSide) {
          log = opts.opts['Server log']
          executionId = log.flowLogId
        } else {
          log = {clientExecutionId: executionId, target: flow.flow}
        }
        let output = Value.dataNode({output: opts.output, executionId: Value.v(executionId, 'string')})
        this.endOperation(output, log)
      }
    }).catch((args) => {
      let [type, message, exception, log] = [...args]
      this.endOperationException(type, message, null, exception, log, null)
    })
  } else if (['BRWS_LocalStorageItemSet', 'BRWS_LocalStorageItemGet', 'BRWS_LocalStorageItemRemove'].indexOf(this.flow.id) > -1) {
    try {
      let res = null
      switch (this.flow.id) {
        case 'BRWS_LocalStorageItemSet':
          if (!MC.isNull(input.data)) {
            localStorage.setItem(input.key, JSON.stringify(input.data))
          } else {
            localStorage.removeItem(input.key)
          }
          break
        case 'BRWS_LocalStorageItemGet': 
          res = localStorage.getItem(input.key)
          res = {data: res ? JSON.parse(res) : null}
          break
        case 'BRWS_LocalStorageItemRemove': 
          localStorage.removeItem(input.key)
          break
      }
      this.endOperation(Value.fromJson(res))
    } catch (e) {
      this.endOperationException('SYS_TechnicalExc', e.message)
    }
  } else if (this.flow.id == 'BRWS_GetBase') {
    const base = document.querySelector('base').href.split('?')[0]
    this.endOperation(Value.fromJson({base: base.substring(0, base.lastIndexOf("/") + 1)}))
  } else if (this.flow.id == 'BRWS_GetThisRi') {
    this.endOperation(Value.fromJson({ri: this.reactFlow().props.mconf.rbPath}))
  } else if (this.flow.id == 'BRWS_GetRi') {
    let base = document.querySelector('base').href.split('?')[0]
    base = base.substring(0, base.lastIndexOf("/") + 1)
    this.endOperation(Value.fromJson({ri: window.location.href.substring(base.length)}))
  } else if (this.flow.id == 'BRWS_SetRi') {
    let win = window
    let target = input.target
    if (target == 'top') {
      win = window.top
    } else if (target == 'parent') {
      win = window.parent
    }
    let base = win.document.querySelector('base').href.split('?')[0]
    base = base.substring(0, base.lastIndexOf("/") + 1)
    let currRi = win.location.href.substring(base.length)
    let url = input.ri
    url = MC.ensureSystemParameters(currRi, url)
    if (target == 'blank') {
      window.open(base + url)
    } else if (url !== currRi) {
      if (!win.history.state) {
        win.history.pushState({mncrouting: true}, '', base + currRi)
      }
      win.history.pushState({mncrouting: true}, '', base + url)
    }
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'FWK_Wait_FE') {
    setTimeout(() => { self.endOperation(Value.v(null))}, input.waitTime)
  } else if (['FWK_ListFlowLogEntries', 'FWK_ListFlowLogEntriesTree'].indexOf(this.flow.id) > -1) {
    this.endOperation(Value.fromJson(MCHistory.listFLowLog(input.executionId, 'FWK_ListFlowLogEntriesTree' == this.flow.id)))
  } else if (this.flow.id == 'FWK_ListLogMessages') {
    this.endOperation(Value.fromJson({'messages': MCHistory.getLog().map(m => { return {type: m.type, mess: m.mess, time: MC.luxonToDateTimeString({v: DateTime.fromJSDate(m.time)}, 'dateTime', false)} })}))
  } else if (this.flow.id == 'BRWS_LinkSet') {
    let link = input.rel == 'icon' ? document.querySelector("link[rel~='icon']") : document.querySelector("link[data-key~='" + input.key + "']")
    if (!link) {
      link = document.createElement('link')
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.dataset.key = input.key
    link.rel = input.rel
    link.type = input.type
    link.href = MC.rebaseUrl(this.parentFlow, input.href)
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'BRWS_LinkRemove') {
    let link = document.querySelector("link[data-key~='" + input.key + "']")
    if (link) {
      link.disabled = true
      link.remove()
    }
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'BRWS_StyleSet') {
    let style = document.querySelector("style[data-key~='" + input.key + "']")
    if (!style) {
      style = document.createElement('style')
      document.getElementsByTagName('head')[0].appendChild(style)
      style.dataset.key = input.key
      style.type = 'text/css'
    }
    if (style.styleSheet) {
      style.styleSheet.cssText = input.css
    } else {
      style.appendChild(document.createTextNode(input.css))
    }
    this.endOperation(Value.v(null)) 
  } else if (this.flow.id == 'BRWS_StyleRemove') {
    let style = document.querySelector("style[data-key~='" + input.key + "']")
    if (style) {
      style.remove()
    }
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'BRWS_ScriptLoad') {
    let script = document.querySelector("script[data-key~='" + input.key + "']")
    if (script) {
      script.remove()
    }
    script = document.createElement("script")
    script.type = "text/javascript"
    script.dataset.key = input.key
    document.body.appendChild(script)
    script.onload = () => {
      this.endOperation(Value.v(null))
    }
    script.onerror = () => {
      this.endOperationException('SYS_InvalidModelExc', 'Error loading script from url ' + input.src)
    }
    script.src = MC.rebaseUrl(this.parentFlow, input.src)
  } else if (this.flow.id == 'BRWS_ScriptEval') {
    let script = document.querySelector("script[data-key~='" + input.key + "']")
    if (script) {
      script.remove()
    }
    script = document.createElement("script")
    script.type = "text/javascript"
    script.dataset.key = input.key
    document.body.appendChild(script) 
    script.appendChild(document.createTextNode(input.script))
    this.endOperation(Value.v(null))
  } else if (this.flow.id == 'BRWS_HttpRequestSend') {
    let self = this
    if (input.body && 'application/octet-stream' == input.contentType) {
      input.body = MC.base64ToBlob(input.body.split(';base64,')[1])
    }
    MC.callServer(input.method, input.url, input.accept, input.body, input.contentType, input.headers, input.timeout).then(function (res) {
      try {
        let output = {status: res.status, body: res.content, contentType: res.contentType, headers: res.headers}
        if (res.status == 200 || res.status == 204) {
          self.endOperation(Value.fromJson(output))
        } else {
          let type = 'SYS_IntegrationExc'
          let message = 'Calling requet by BRWS_HttpRequestSend failed for url ' + url  + '! Status:' + res.status
          self.endOperationException(type, message, input, output, null, self.inputMapTrace)
          return
        }
      } catch (e) {
        self.endOperationException('SYS_IntegrationExc', e.message, input, null, null, self.inputMapTrace)
        return
      }
    }).catch(function (err) {
      if (navigator.onLine) {
        self.endOperationException('SYS_IntegrationExc', 'Calling requet by BRWS_HttpRequestSend failed for url ' + input.url + ': ' + err.message, input, null, null, self.inputMapTrace)
        return
      } else {
        self.endOperationException('SYS_SystemUnavailableExc', 'Internet connection is not available, url ' + input.url + ': ' + err.message, input, null, null, self.inputMapTrace)
        return
      }
    })
  } else {
    this.endOperationException('SYS_InvalidModelExc', 'Unsupported framework operation: ' + this.flow.id)
  }
}

Flow.prototype.updateEnvContext = function(context) {
  this.context.data.env.value.context = Value.fromJson(context)
  if (!MC.isNull(context) && MC.isPlainObject(context.request) && !MC.isNull(context.request.language)) {
    this.setLang(context.request.language)
  }
  if (this.parentFlow) {
    this.parentFlow.updateEnvContext(context)
  }
  if (this.debug('TRACE')) {
    MCHistory.history(this, null, 'ENVIRONMENT UPDATE', {'Environment': this.context.data.env}, {end: Date.now()})
  }
}

Flow.prototype.initLogicTimer = function(logic) {
  const self = this
  if (MC.isNumeric(logic.timer) && logic.timer > 0) {
    this.logicTimers[logic.name] = setTimeout(() => { 
      self.eventForm(null, null, null, null, {logic: logic})
    }, 1000*logic.timer) 
  }
}

Flow.prototype.initLogicTimers = function() {
  this.clearLogicTimers()
  if (this.reactFlow().state.formData.logic && Array.isArray(this.reactFlow().state.formData.logic)) {
    for (let logic of this.reactFlow().state.formData.logic) {
      this.initLogicTimer(logic)
    }
  }
}

Flow.prototype.clearLogicTimers = function() {
  if (!this.logicTimers) return
  for (let timerKey in this.logicTimers) {
    clearTimeout(this.logicTimers[timerKey])
  }
}

Flow.prototype.getCfgParameter = function(key) {
  let conf = Value.getProperty(this.context.data.env, 'configuration')
  if (Value.isDataNode(conf) && !Value.isNullOrEmpty(conf)) {
    return Value.getProperty(conf, key).value
  }
  return null
}

Flow.prototype.runDecisionTableOperation = function() {
  let input = this.input || Value.dataNode({})
  let inputdef = this.flow.input
  let output = {}
  let decisions = this.flow.decision
  let testedRows = []
  if (Array.isArray(decisions) && decisions.length > 0) {
    for (let row of decisions) {
      let rowTrace = {}
      let matches = true
      for (let inpar of inputdef) {
        let inputVal = Value.v(Value.getProperty(input, inpar.name).value, inpar.basictype)
        let rowVal = MC.findByObjectParamValue(row.input, "name", inpar.name)
        rowVal = Value.v(rowVal ? rowVal.val : null, inpar.basictype)
        if (inputVal.value !== rowVal.value) {
          matches = false
        }
        if (this.debug('TRACE')) {
          rowTrace[inpar.name] = Value.toLiteral(rowVal)
        }
      }
      if (this.debug('TRACE')) {
        testedRows.push(rowTrace)
      }
      if (matches) {
        for (let outpar of this.flow.output) {
          let outVal = MC.findByObjectParamValue(row.output, "name", outpar.name)
          if (outVal) {
            output[outpar.name] = Value.v(outVal.val, outpar.basictype)
          }
        }
        break
      }
    }
  }
  this.endOperation(MC.isEmptyObject(output) ? Value.v(null) : Value.dataNode(output), {'Tested rows': testedRows})
}

Flow.prototype.getRootFlow = function() {
  return this.parentFlow ? this.parentFlow.getRootFlow() : this
}

Flow.prototype.reactFlow = function() {
  return this.getRootFlow().reactFlowObj
}

Flow.prototype.debug = function(requestedLevel) {
  if (!requestedLevel) {
    requestedLevel = 'NONE'
  }
  const levels = ['NONE', 'MINIMAL', 'BASIC', 'DETAIL', 'TRACE']
  if (this.logLevel) {
    if (levels.indexOf(requestedLevel) <= levels.indexOf(this.logLevel)) {
      return true
    } else {
      return false
    }
  }
  return false
}

Flow.prototype.runLazyUpdate = function(field, paging) {
  if (!paging) {
    MC.putFieldParamValue(field.param, 'settings/@page', null)
  }
  const opts = this.getDataActionOpts(field)
  if (opts.action) {
    this.paginateForm(opts)
  } else {
    this.submitForm(field, 'store', null, this.formExecutionId)
  }
}

Flow.prototype.runRulesetOperation = function() {
  let context = {input: this.input || {}}
  context['env'] = Value.v(null)
  context['svl'] = Value.v(null)
  context['vmt'] = Value.v(null)
  for (let prop in this.context.data) {
    if (prop == 'env' || prop == 'svl' || prop == 'vmt') {
      context[prop] = this.context.data[prop]
    }
  }
  let testedRules = {}
  let trc = this.debug('TRACE')
  let opts = {function: this.flow.function, trace: trc}
  if (Array.isArray(this.flow.rule) && this.flow.rule.length > 0) {
    for (let rule of this.flow.rule) {
      let passed = true
      if (rule.when) {
        if (rule.when.expr && Array.isArray(rule.when.expr)) {
          for (let expr of rule.when.expr) {
            let expression = new Expression()
            expression.init(expr, context, opts)
            let res = expression.evaluate()
            if (Value.isError(res)) {
              this.endOperationException('SYS_MappingExc', Value.getErrorMessage(res))
              return false
            }
            if (res.type === 'variable') {
              res = true
            }
            res = Value.castToScalar(res, 'boolean')
            if (Value.isNullOrEmpty(res)) { 
              this.endOperationException('SYS_MappingExc', 'When expression of ruleset operation with name "' + this.flow.id + '" must evaluate to boolean, but result value is null or empty!', null, null, null, {'Input': Value.toLiteral(this.input), 'Tested rules': testedRules})
              return
            }
            if (trc) {
              testedRules[rule.name] = {result: Value.toLiteral(res), trace: expression.getTrace()}
            }
            if (Value.isFalse(res)) {
              passed = false
              break
            }
          }
        }
      }
      if (passed) {
        let logContext = MC.extend({}, context)
        let [output, trace] = this.mapToResultObject({mapping: rule.then && rule.then.expr ? rule.then.expr : null}, {data: context})
        if (!output) {
          this.endOperationException('SYS_MappingExc', trace[0], logContext, null, null, trace[1])
          return
        }
        this.endOperation(output, {'Input': Value.toLiteral(this.input), 'Tested rules': testedRules, 'Output': output})
        return
      }
    }
  }
  this.endOperationException('SYS_NoRuleSetConditionSatisfiedExc', 'No rule set condition satisfied!', null, null, null, {'Input': Value.toLiteral(this.input), 'Tested rules': testedRules})
}

Flow.prototype.adjustNsMap = function(eNs) {
  let ns = Value.getProperty(this.context.data.env, 'ns')
  if (Value.isCollectionNotEmpty(eNs)) {
    for (let it of Value.collectionValue(eNs)) {
      if (Value.getProperty(it, 'prefix').value.startsWith('adhocns') && !Value.collectionValue(ns).find(i => Value.getProperty(i, 'uri').value == Value.getProperty(it, 'uri').value)) {
        Value.collectionValue(ns).push(it)
      }
    }
  }
}

export {Flow}
